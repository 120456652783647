import { toast } from "react-toastify";
import { getFromStorage } from "./storage";
import { STORAGE_KEYS } from "./constants/storageKeys";

const language = getFromStorage(STORAGE_KEYS.language);

export const successToast = (message: string) => {
  toast.dismiss();
  toast.success(message);
};

export const showWarning = (message: string) => {
  toast.dismiss();
  toast.warning(
    message || (language === "ar" ? "هناك خطأ ما" : "Something went wrong")
  );
};

export const errorToast = (message: string) => {
  toast.dismiss();
  toast.error(
    message || (language === "ar" ? "هناك خطأ ما" : "Something went wrong")
  );
};
