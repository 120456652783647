// import secureLocalStorage from "react-secure-storage";

export const setToStorage = (key: string, data: string) => {
  localStorage.setItem(key, data);
};

export const getFromStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key);
};
