import AddProduct from "./addProduct";
import Chat from "./chat";
import ChooseCategory from "./chooseCategory";
import Filters from "./filters";
import HomePage from "./home";
import ProductDetail from "./productDetail";
import Profile from "./profile";
import Wishlist from "./wishlist";
import Notifications from "./notifications";
import SeeAll from "./seeAll";
import ContactUs from "./contactUs";
import AboutUs from "./aboutUs";
import Faqs from "./faq";
import PrivacyPolicy from "./privacyPolicy";
import ViewProfile from "./viewProfile";
import ServerMaintainance from "./serverMaintainance";
import SearchedProducts from "./searchedProducts";
import TermsConditions from "./terms&Conditions";
import ConsumerRights from "./consumerRights";
import OfficialStores from "./officialStores";
import Blogs from "./blog";
import SeasonalOffers from "./seasonalOffers";
import Sell from "./sell";
import WeeklyDeals from "./weeklyDeals";
import AllBlogs from "./allBlogs";
import BlogDetail from "./blogDetail";
import TopManufacturers from "./top-manufacturers";

export default {
  HomePage,
  ProductDetail,
  ChooseCategory,
  Wishlist,
  Profile,
  AddProduct,
  Chat,
  Filters,
  Notifications,
  SeeAll,
  ContactUs,
  AboutUs,
  Faqs,
  PrivacyPolicy,
  ViewProfile,
  ServerMaintainance,
  SearchedProducts,
  TermsConditions,
  ConsumerRights,
  OfficialStores,
  Blogs,
  SeasonalOffers,
  Sell,
  WeeklyDeals,
  AllBlogs,
  BlogDetail,
  TopManufacturers,
};
