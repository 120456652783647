import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import "./Components.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useTranslation from "../hooks/Translation";
import { STORAGE_KEYS, getFromStorage } from "../helpers";
import { GoogleTranslateScript } from "../utils/GoogleTranslateScript";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type ProductType = {
  product_id: string;
  product_image: string;
  product_feature: string;
  product_name: string;
  product_price: number;
  location: string;
  wishlist?: boolean;
  category_id: string;
  listing_date?: string;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setSelectedId: Dispatch<SetStateAction<string>>;
  booster_applied: boolean;
  lang?: number;
};

const ProductListingCard = ({
  product_id,
  product_image,
  product_feature,
  product_name,
  product_price,
  location,
  wishlist,
  listing_date,
  category_id,
  setOpenModal,
  setSelectedId,
  booster_applied,
  lang,
}: ProductType) => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const [translatedProductName, setTranslatedProductName] = useState("");

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setOpenModal(true);
    setSelectedId(product_id);
  };

  const targetLanguage = language === "ar" ? "ar" : "en";

  const handleTranslate = async () => {
    if (product_name) {
      const translatedText = await GoogleTranslateScript(
        product_name,
        targetLanguage
      );
      setTranslatedProductName(translatedText);
    }
  };
  // useEffect(() => {
  //   handleTranslate();
  // }, [language]);
  return (
    <div
      className="prdct_listing_crd"
      onClick={() =>
        navigate(`/product-detail/${product_id}`, {
          state: { page: "my_listing" },
        })
      }
    >
      <figure
        className="right_arrw"
        style={{
          right: language === "ar" ? "unset" : "14px",
          left: language === "ar" ? "14px" : "unset",
        }}
      >
        <LazyLoadImage
          alt="right_arrow"
          src={"/static/images/right_arrow.svg"}
          effect="blur"
          height={"100%"}
          width={"100%"}
        />
      </figure>
      <div className="lst_crd_lft">
        <figure
          style={{
            marginRight: language === "ar" ? 0 : "10px",
            marginLeft: language === "ar" ? "10px" : "0px",
          }}
        >
          <LazyLoadImage
            alt="product_image"
            src={product_image || ""}
            effect="blur"
            height={"100%"}
            width={"100%"}
          />
        </figure>
        <div className="lst_dtl">
          <h6>
            {product_feature || ""}{" "}
            <figure>
              {booster_applied ? (
                <LazyLoadImage
                  alt="booster"
                  src={"/static/images/booster.png"}
                  effect="blur"
                  height={"100%"}
                  width={"100%"}
                />
              ) : null}
            </figure>
          </h6>
          <h3>
            {translation.Globals.aed}{" "}
            {product_price.toLocaleString("en-IN") || ""}
          </h3>
          <h4>
            {" "}
            {product_name?.length > 35
              ? product_name.slice(0, 35) + "...."
              : product_name || ""}
          </h4>
          <p>{moment(listing_date).format("ll")}</p>
        </div>
      </div>
      <div className="lst_crd_ryt">
        <figure onClick={(e: any) => handleOpenModal(e)}>
          <LazyLoadImage
            alt="delete"
            src={"/static/images/delete.png"}
            effect="blur"
            height={"100%"}
            width={"100%"}
          />
        </figure>
      </div>
    </div>
  );
};

export default ProductListingCard;
