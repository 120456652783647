import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { END_POINTS, WORDPRESS_URL } from "../../helpers";
import {
  WORDPRESS_PASSWORD,
  WORDPRESS_USERNAME,
} from "../../helpers/constants/urls";
import Layout from "../../layout";
import { BlogsCategories } from "../../features";
import { BlogCard, BlogCardSkeleton, Pagination } from "../../components";
import { Skeleton } from "@mui/material";

const AllBlogs = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [blogs, setBlogs] = useState<any>();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(0);

  let totalPages = Math.ceil(count / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getBlogsByCat = async (id: any) => {
    const url = `${WORDPRESS_URL}${
      END_POINTS.blogsByCategory
    }/${id}?page=${page}&per_page=${12}`;

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${btoa(
            `${WORDPRESS_USERNAME}:${WORDPRESS_PASSWORD}`
          )}`,
        },
      });
      if (response?.ok) {
        const responseData = await response.json();
        if (responseData?.status === 200) {
          setBlogs(responseData?.data[0]);
          setCount(responseData?.total_posts);
          setLoading(false);
        }
      } else {
        setBlogs([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    if (id) {
      getBlogsByCat(id);
    }
  }, [id, page]);

  return (
    <Layout>
      {loading ? (
        <section className="blog_cat container">
          <div className="blg_hd">
            <h2>
              {" "}
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem" }}
                width={"250px"}
                animation="wave"
              />
            </h2>
          </div>
          <div className="blog_data">
            {Array.from({ length: 6 }, (_, index) => (
              <BlogCardSkeleton key={index} />
            ))}
          </div>
        </section>
      ) : (
        <>
          <section className="blog_cat container">
            <div className="blg_hd">
              <h2>{blogs?.heading || ""}</h2>
            </div>
            <div className="blog_data">
              {blogs?.data?.length
                ? blogs?.data?.map((val: any) => {
                    return (
                      <BlogCard
                        key={val?.blog_id}
                        image={val?.main_image || ""}
                        date={val?.date || ""}
                        author={val?.author || ""}
                        heading={val?.heading || ""}
                        blogId={val?.blog_id || ""}
                        cat={val?.cat || ""}
                        description={val?.description || ""}
                      />
                    );
                  })
                : null}
            </div>
          </section>
          <div className="vw_prfl_pgntn">
            <Pagination
              module={blogs?.data}
              page={page || 1}
              onPageChange={onPageChange}
              totalPages={totalPages}
              setPage={setPage}
            />
          </div>
        </>
      )}
    </Layout>
  );
};

export default AllBlogs;
