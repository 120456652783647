import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";
import { AddressRes, Category } from "../types/General";

export interface General {
  cms: CMS | null;
  isAuthModalVisible: boolean;
  ModalType: string;
  email: string;
  phoneNo: string;
  countryCode: string;
  address: AddressRes[];
  categories: Category[];
  // filters: Filters | null;
}
export interface CMS {
  countryCode: string;
  email: string;
  message: string;
  phone: string;
  privacyPolicy: string;
  about_us: string;
  about_us_ar: string;
  privacyPolicy_ar: string;
  termsAndCondition: string;
  termsAndCondition_ar: string;
  consumerRights_ar: string;
  consumerRights: string;
  _id: string;
}
export interface Filters {
  categoryId: string;
  page: number;
  minPrice: number;
  maxPrice: number;
  sort: string;
  brand: string[];
  subCategoryId: string;
}

const initialState: General = {
  cms: null,
  address: [],
  categories: [],
  // filters: null,
  isAuthModalVisible: false,
  ModalType: "",
  email: "",
  phoneNo: "",
  countryCode: "",
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    resetCms: (state, action: PayloadAction<Pick<General, "cms">>) => {
      state.cms = action.payload.cms;
    },

    toggleAuthModal: (
      state,
      action: PayloadAction<Pick<General, "isAuthModalVisible" | "ModalType">>
    ) => {
      state.isAuthModalVisible = action.payload.isAuthModalVisible;
      state.ModalType = action.payload.ModalType;
    },
    verificationData: (
      state,
      action: PayloadAction<Pick<General, "email" | "phoneNo" | "countryCode">>
    ) => {
      state.email = action.payload.email;
      state.phoneNo = action.payload.phoneNo;
      state.countryCode = action.payload.countryCode;
    },
    addressData: (state, action: PayloadAction<Pick<General, "address">>) => {
      state.address = action.payload.address;
    },
    categoryData: (
      state,
      action: PayloadAction<Pick<General, "categories">>
    ) => {
      state.categories = action.payload.categories;
    },
    // filtersData: (state, action: PayloadAction<Pick<General, "filters">>) => {
    //   state.filters = action.payload.filters;
    // },
  },
});
export const {
  resetCms,
  toggleAuthModal,
  verificationData,
  addressData,
  categoryData,
} = generalSlice.actions;
export const getCms = (state: RootState) => state.general.cms;
export const isAuthModalVisible = (state: RootState) =>
  state.general.isAuthModalVisible;
export const ModalType = (state: RootState) => state.general.ModalType;
export const email = (state: RootState) => state.general.email;
export const phoneNo = (state: RootState) => state.general.phoneNo;
export const country = (state: RootState) => state.general.countryCode;

export const address = (state: RootState) => state.general.address;
export const categories = (state: RootState) => state.general.categories;
// export const filters = (state: RootState) => state.general.filters;

export default generalSlice.reducer;
