export const STORAGE_KEYS = {
  token: "TOKEN",
  tempToken: "TEMP_TOKEN",
  credentials: "CREDENTIALS",
  location: "LOCATION",
  userData: "USER_DATA",
  fcmToken: "FCM_TOKEN",
  language: "LANGUAGE",
  googleTranslation: "GOOGLE_TRANSLATION",
  selectedLang: "selectedLang",
  locationPermission: "locationPermission",
  timeStamp: "timeStamp",
};
