import moment from "moment";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const SeasonalOffer1 = ({ offer1 }: any) => {
  const [timeRemaining, setTimeRemaining] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const targetDate = moment(offer1?.valid_till, "YYYY-MM-DD HH:mm:ss");
    const interval = setInterval(() => {
      const now = moment();
      const duration = moment.duration(targetDate.diff(now));

      if (duration.asSeconds() <= 0) {
        clearInterval(interval);
        setTimeRemaining({
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      } else {
        setTimeRemaining({
          hours: String(Math.floor(duration.asHours())).padStart(2, "0"),
          minutes: String(duration.minutes()).padStart(2, "0"),
          seconds: String(duration.seconds()).padStart(2, "0"),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [offer1]);
  return (
    <section className="ssnl_offr1 container">
      <div className="ofr1_lft">
        <figure>
          <LazyLoadImage
            src={offer1?.image}
            alt="Offer image"
            effect="blur"
            width={"100%"}
          />
        </figure>
      </div>
      <div className="ofr1_ryt">
        <h2>{offer1?.heading || ""}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: offer1?.description || "",
          }}
        />
        <ul className="offr_time">
          <li>
            <h6>{timeRemaining.hours}</h6>
          </li>
          <li>
            <h6>{timeRemaining.minutes}</h6>
          </li>
          <li>
            <h6>{timeRemaining.seconds}</h6>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SeasonalOffer1;
