import {
  Box,
  Button as MuiButton,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";

import { useFormik } from "formik";
import * as Yup from "yup";
import { isNumber, isString } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import {
  useDeleteCardMutation,
  useLazyGetCardQuery,
  useSaveCardMutation,
} from "../../service/payments";

import { STRIPE_TEST_KEY } from "../../helpers/constants/urls";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CardRes, CommonBody } from "../../types/General";
import { Button } from "../../components";
import { loadStripe } from "@stripe/stripe-js";

const ManagePayments = () => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const token = getFromStorage(STORAGE_KEYS.token);
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [open, setOpen] = useState<boolean>(false);
  const style = generateResponsiveStyle();
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [cards, setCards] = useState<CardRes[]>([]);
  const [addCard, GetAddCardData] = useSaveCardMutation();
  const [getCards, { isLoading }] = useLazyGetCardQuery();
  const [deleteCard, GetDeleteCardData] = useDeleteCardMutation();

  loadStripe(STRIPE_TEST_KEY);

  const getAllCards = async () => {
    try {
      const response = await getCards({}).unwrap();
      if (response?.statusCode === 200) {
        setCards(response?.data?.data || []);
      } else {
        setCards([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const cardImage = (cardType: string) => {
    switch (cardType?.toLowerCase()) {
      case "visa":
        return "/static/images/visa.png";
      case "electron":
        return "/static/images/electron.png";
      case "maestro":
        return "/static/images/maestro.png";
      case "dankort":
        return "/static/images/dankort.png";
      case "interpayment":
        return "/static/images/interpayment.png";
      case "unionpay":
        return "/static/images/unionpay.png";
      case "mastercard":
        return "/static/images/mastercard.png";
      case "american express":
        return "/static/images/amex.png";
      case "diners club":
        return "/static/images/dinners.png";
      case "discover":
        return "/static/images/discover.png";
      case "jcb":
        return "/static/images/jcb.png";
      default:
        return "/static/images/visa.png";
    }
  };

  const handleDeleteCard = async (cardId: string) => {
    const data = {
      card: cardId,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await deleteCard(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          successToast(res?.message || "");
          getAllCards();
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as any)["Stripe"].setPublishableKey(STRIPE_TEST_KEY);
      };
      window.document.body.appendChild(s);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    },
    validationSchema: Yup.object({
      cardHolderName: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.card_holder_name)
        .max(20),
      cardNumber: Yup.string()
        .required(translation.validations.required_field)
        .label("Card number")
        .min(16, translation.validations.cardNo_val),
      expiryDate: Yup.string().required(translation.validations.required_field),
      cvv: Yup.string()
        .required(translation.validations.required_field)
        .label("CVV")
        .min(3, translation.validations.cvv_val),
    }),
    onSubmit: (values) => {
      formik.setSubmitting(true);

      let data = {
        number: formik.values.cardNumber,
        exp_month: formik.values.expiryDate.slice(0, 2),
        exp_year: formik.values.expiryDate.slice(-4),
        cvc: formik.values.cvv,
      };

      if (window?.Stripe) {
        // @ts-ignore
        window?.Stripe.card.createToken(
          {
            number: formik.values.cardNumber?.slice(0, 19),
            exp_month: String(formik.values.expiryDate).slice(0, 2),
            exp_year: String(formik.values.expiryDate).slice(-2),
            cvc: formik.values.cvv?.trim(),
            name: formik.values.cardHolderName?.trim(),
          },
          async (status: number, response: any) => {
            if (status === 200) {
              let body = {
                token: response?.id,
              };
              try {
                let encryptedBody = generateEncryptedKeyBody(
                  body
                ) as CommonBody;

                const res = await addCard(encryptedBody).unwrap();
                if (res?.statusCode === 200) {
                  if (res?.data) {
                    successToast(res?.message || "Card added successfully");
                    setOpen(false);
                    getAllCards();
                    formik.resetForm();
                  }
                }
              } catch (error: any) {
                errorToast(error?.data?.message || "");
              }
            } else {
              errorToast(response.error.message);
            }
          }
        );
      }

      formik.setSubmitting(false);
    },
  });
  useEffect(() => {
    // if (token) {
    getAllCards();
    // }
  }, []);
  return (
    <div className="main">
      <Loader
        isLoad={
          isLoading || GetDeleteCardData?.isLoading || GetAddCardData?.isLoading
        }
      />
      <h2>{translation.profile.manage_payments}</h2>
      <ul className="cards_sec">
        {cards?.length ? (
          cards?.map((item) => {
            return (
              <li className="mn_crds">
                <div className="crd_dtls">
                  {item?.brand ? (
                    <figure>
                      <img src={cardImage(item?.brand)} alt="img" />
                    </figure>
                  ) : (
                    <figure>
                      <img src={"/static/images/visa.png"} alt="img" />
                    </figure>
                  )}

                  <div className="dtl_mn">
                    <h4>•••• •••• •••• {item?.last4 || ""}</h4>
                    <p>
                      {translation.Globals.expiry_date || ""} -{" "}
                      {item?.exp_month || ""}/{item?.exp_year || ""}
                    </p>
                  </div>
                </div>
                <figure
                  className="delete_dv"
                  onClick={() => {
                    setOpenDelete(true);
                    setSelectedCard(item?.id);
                  }}
                >
                  <img src="/static/images/delete.png" alt="delete" />
                </figure>
              </li>
            );
          })
        ) : (
          <div
            className="noData paymnt_nodata"
            style={{ padding: "120px 0 20px 0" }}
          >
            <figure>
              <img src="/static/images/no_card.png" alt="no_card" />
            </figure>
            <h3>{translation.profile.no_cards}</h3>
          </div>
        )}
      </ul>

      <div className="add_crd" onClick={() => setOpen(true)}>
        <figure
          style={{
            marginRight: language === "ar" ? 0 : "10px",
            marginLeft: language === "ar" ? "10px" : "0px",
          }}
        >
          <img src="/static/images/add_card.svg" alt="add_card" />
        </figure>
        <div className="add_details">
          <h4>{translation.profile.new_card}</h4>
          <p>{translation.profile.pay_via}</p>
          <div className="paymnt_cards">
            <figure
              style={{
                marginRight: language === "ar" ? 0 : "10px",
                marginLeft: language === "ar" ? "10px" : "0px",
              }}
            >
              <img src="/static/images/visa.svg" alt="visa" />
            </figure>
            <figure
              style={{
                marginRight: language === "ar" ? 0 : "10px",
                marginLeft: language === "ar" ? "10px" : "0px",
              }}
            >
              <img src="/static/images/master-card.svg" alt="master-card" />
            </figure>
            <figure
              style={{
                marginRight: language === "ar" ? 0 : "10px",
                marginLeft: language === "ar" ? "10px" : "0px",
              }}
            >
              <img src="/static/images/discover.svg" alt="discover" />
            </figure>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="payment_modl">
            <div className="cross">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <h2>{translation.profile.new_card}</h2>
            <h5>{translation.profile.enter_details}</h5>
            {/* <Elements stripe={stripePromise}> */}
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* <Typography variant="h6" className="custom_label2">
                    Card holder name
                  </Typography> */}
                  <TextField
                    fullWidth
                    value={formik.values.cardHolderName}
                    id="standard-basic"
                    placeholder={translation.profile.holder_name}
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    name="cardHolderName"
                    inputProps={{ maxLength: 20 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.cardHolderName &&
                      formik.errors.cardHolderName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    placeholder={translation.profile.card_number}
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    name="cardNumber"
                    inputProps={{ maxLength: 16 }}
                    value={formik.values.cardNumber}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.cardNumber && formik.errors.cardNumber
                    }
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: "16px",
                    paddingTop: "16px",
                  }}
                >
                  <Grid item xs={5}>
                    <TextField
                      className="paymnt_modl_txtfld"
                      variant="standard"
                      placeholder="MM/YYYY"
                      inputProps={{ maxLength: 7 }}
                      value={formik.values.expiryDate}
                      name="expiryDate"
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .replace(/\D/g, "") // Remove non-numeric characters
                          .replace(/(\d{2})(\d{0,4})/, "$1/$2"); // Format as MM/YYYY

                        formik.handleChange({
                          target: {
                            name: "expiryDate",
                            value: formattedValue,
                          },
                        });
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Backspace") {
                          // Allow backspace for the month part
                          formik.handleChange({
                            target: {
                              name: "expiryDate",
                              value: formik.values.expiryDate.slice(0, -1),
                            },
                          });
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.expiryDate && formik.errors.expiryDate
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    {/* <Typography variant="h6" className="custom_label2">
                      CVV
                    </Typography> */}
                    <TextField
                      fullWidth
                      id="standard-basic"
                      placeholder={translation.profile.cvv}
                      variant="standard"
                      className="paymnt_modl_txtfld"
                      name="cvv"
                      inputProps={{ maxLength: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <figure className="cvv_img">
                              <img src="/static/images/cvv.svg" alt="cvv" />
                            </figure>
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.cvv}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.cvv && formik.errors.cvv}
                    />
                  </Grid>
                </Box>
                {/* <Grid item xs={12} className="sv_crd">
                  <FormControlLabel
                    control={<Checkbox />}
                    // checked={formik.values.remember}
                    name="remember"
                    // onChange={formik.handleChange}
                    label={translation.profile.save_card}
                  />
                </Grid> */}

                <Box style={{ padding: "30px 0 20px 16px", width: "100%" }}>
                  <Button value={translation.Globals.save} />
                </Box>
              </Grid>
            </form>
            {/* </Elements> */}
          </div>
        </Box>
      </Modal>
      <Modal
        // BackdropClick={false}

        open={openDelete}
        onClose={() => setOpenDelete(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenDelete(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.Globals.delete_card || ""}
            </h2>
            <div className="logut_flx">
              <MuiButton
                className="yesBtn"
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => {
                  setOpenDelete(false);
                  handleDeleteCard(selectedCard);
                }}
                value={translation.Globals.yes}
              >
                {translation.Globals.yes}
              </MuiButton>

              <MuiButton
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => setOpenDelete(false)}
              >
                {translation.Globals.no}
              </MuiButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ManagePayments;
