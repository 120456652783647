import React, { useEffect, useState } from "react";
import "./Blogs.scss";
import Layout from "../../layout";
import { END_POINTS, WORDPRESS_URL } from "../../helpers";
import {
  WORDPRESS_PASSWORD,
  WORDPRESS_USERNAME,
} from "../../helpers/constants/urls";
import { BlogBanner, BlogsCategories } from "../../features";
import { Skeleton } from "@mui/material";
import { BlogCardSkeleton } from "../../components";

const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState(null);
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    const url = `${WORDPRESS_URL}${END_POINTS.blogs}`;

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${btoa(
            `${WORDPRESS_USERNAME}:${WORDPRESS_PASSWORD}`
          )}`,
        },
      });
      if (response?.ok) {
        setLoading(false);
        const responseData = await response.json();
        if (responseData?.status === 200) {
          setBannerData(responseData?.data?.banner);
          setBlogs(responseData?.data?.categories);
        }
      } else {
        setBlogs([]);
        setBannerData(null);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false); // Set loading state to false in case of error
      throw error; // Re-throw the error after logging it
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  // useEffect(() => {
  //   const reloadKey = "reloadBlogsPage";

  //   if (!sessionStorage.getItem(reloadKey)) {
  //     sessionStorage.setItem(reloadKey, "true");
  //     window.location.reload();
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   } else {
  //     sessionStorage.removeItem(reloadKey);
  //     getBlogs();
  //   }
  // }, []);

  return (
    <Layout>
      {loading ? (
        <>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={"300px"}
          />
          {Array.from({ length: 3 }, (_, index) => (
            <section className="blog_cat container">
              <div className="blg_hd">
                <h2>
                  {" "}
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "2rem" }}
                    width={"250px"}
                    animation="wave"
                  />
                </h2>
              </div>
              <div className="blog_data">
                {Array.from({ length: 3 }, (_, index) => (
                  <BlogCardSkeleton key={index} />
                ))}
              </div>
            </section>
          ))}
        </>
      ) : (
        <>
          <BlogBanner bannerData={bannerData} />
          <BlogsCategories blogs={blogs} />
        </>
      )}
    </Layout>
  );
};

export default Blogs;
