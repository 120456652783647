import { useEffect, useState } from "react";
import Layout from "../../layout";
import { Box, Button, Modal } from "@mui/material";
import "./profile.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  EditProfile,
  ManagePayments,
  MyBooster,
  MyListing,
  TotalFollowers,
  TotalFollows,
} from "../../features";
import useAuth from "../../hooks/useAuth";
import { useDeleteAccountMutation } from "../../service/Auth";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  successToast,
} from "../../helpers";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Profile = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const location = useLocation();
  const { state } = location;
  const user = useAuth();
  const navigate = useNavigate();
  const style = generateResponsiveStyle();
  const dispatch = useAppDispatch();
  const [activeCase, setActiveCase] = useState<number>(0);
  const [newCls, setNewCls] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState(false);
  const [deleteAccount] = useDeleteAccountMutation();

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await deleteAccount({}).unwrap();
      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.account_deleted);

        dispatch(
          setCredentials({
            token: null,
            user: null,
          })
        );
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.userData);

        closeModal();
        navigate("/");
      }
    } catch (error: any) {
      if (error?.data?.message) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const data = [
    {
      id: 1,
      name: translation.profile.my_listing,
      img: "/static/images/listing_icon.svg",
    },
    {
      id: 2,
      name: translation.profile.manage_payments,
      img: "/static/images/payment_icon.svg",
    },

    {
      id: 6,
      name: translation.profile.my_booster,
      img: "/static/images/booster.png",
    },
  ];

  useEffect(() => {
    if (state === "listing") {
      setActiveCase(1);
    }
  }, []);
  const handleActiveCase = () => {
    switch (activeCase) {
      case 1:
        return <MyListing />;
      case 2:
        return <ManagePayments />;

      case 4:
        return <TotalFollowers />;
      case 5:
        return <TotalFollows />;
      case 6:
        return <MyBooster />;
      default:
        return <EditProfile state={state} />;
    }
  };

  const handleSwitch = (id: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setActiveCase(id);
    setNewCls(true);
  };

  useEffect(() => {
    handleActiveCase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCase]);

  useEffect(() => {
    if (state === "location") {
      setActiveCase(3);
    }
  }, []);

  return (
    <Layout>
      <div className="wrapCls" style={{ paddingTop: "0" }}>
        <div className="container">
          <div className="pMain">
            <div className={newCls ? "pLeft lft_prflmob" : "pLeft"}>
              <div
                className="userDetail"
                onClick={() => {
                  setActiveCase(0);
                  setNewCls(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <figure
                  style={{
                    marginRight: language === "ar" ? 0 : "20px",
                    marginLeft: language === "ar" ? "20px" : "0px",
                  }}
                >
                  <LazyLoadImage
                    className={user?.image ? "img_style" : "img_style2"}
                    alt="empty_user"
                    src={user?.image || "/static/images/empty_user.png"}
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                  />
                </figure>
                <div className="username">
                  <h3>{user?.fullName || ""}</h3>
                  <h4>{user?.email || ""}</h4>
                </div>
              </div>
              <div className="follow_dv">
                <div
                  className="flw_sc"
                  onClick={() => {
                    setActiveCase(4);
                    setNewCls(true);
                  }}
                >
                  <p>{translation.view_profile.total_followers}</p>
                  <h6>{user?.followers || 0}</h6>
                </div>
                <div
                  className="flw_sc"
                  onClick={() => {
                    setActiveCase(5);
                    setNewCls(true);
                  }}
                >
                  <p>{translation.view_profile.total_following}</p>
                  <h6>{user?.following || 0}</h6>
                </div>
              </div>
              {user?.boosterBought ? (
                <ul>
                  {data?.map((item) => (
                    <li
                      key={item?.id}
                      className={activeCase === item?.id ? "active_tab" : ""}
                      onClick={() => {
                        setNewCls(true);
                        handleSwitch(item?.id);
                      }}
                    >
                      <figure
                        style={{
                          marginRight: language === "ar" ? 0 : "16px",
                          marginLeft: language === "ar" ? "16px" : "0px",
                        }}
                      >
                        <LazyLoadImage
                          alt="booster"
                          src={item?.img}
                          effect="blur"
                          height={"100%"}
                          width={"100%"}
                        />
                      </figure>
                      <h3>{item?.name}</h3>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul>
                  {data.slice(0, 2)?.map((item) => (
                    <li
                      key={item?.id}
                      className={activeCase === item?.id ? "active_tab" : ""}
                      onClick={() => {
                        setNewCls(true);
                        handleSwitch(item?.id);
                      }}
                    >
                      <figure
                        style={{
                          marginRight: language === "ar" ? 0 : "16px",
                          marginLeft: language === "ar" ? "16px" : "0px",
                        }}
                      >
                        <LazyLoadImage
                          alt="booster"
                          src={item?.img}
                          effect="blur"
                          height={"100%"}
                          width={"100%"}
                        />
                      </figure>
                      <h3>{item?.name}</h3>
                    </li>
                  ))}
                </ul>
              )}

              {user?.role === "ADMIN" ? null : (
                <Button
                  style={{ width: "100%" }}
                  onClick={() => setOpenModal(true)}
                >
                  {translation.profile.delete_account}
                </Button>
              )}
            </div>
            <div className={newCls ? "pRyt ryt_dsply" : "pRyt "}>
              <div className="mob_view_back">
                <LazyLoadImage
                  src="/static/images/back.png"
                  alt="back"
                  onClick={() => setNewCls(false)}
                  effect="blur"
                  width={"100%"}
                  height={"100%"}
                />
              </div>
              {handleActiveCase()}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={closeModal}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.profile.sure_delete}
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{
                  color: "#252525",
                  border: "1px solid #7dbfdd",
                }}
                onClick={() => {
                  setOpenModal(false);
                  handleDeleteAccount();
                }}
              >
                {translation.Globals.yes}
              </Button>

              <Button
                sx={{
                  color: "#252525",
                  border: "1px solid #7dbfdd",
                }}
                onClick={() => setOpenModal(false)}
              >
                {translation.Globals.no}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default Profile;
