import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "../contactUs/Cms.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { getCms, resetCms } from "../../reducers/generalSlice";
import useTranslation from "../../hooks/Translation";
import { Loader, STORAGE_KEYS, getFromStorage } from "../../helpers";
import { useLazyGetCmsQuery } from "../../service/Cms";

const PrivacyPolicy = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const getCmsData = useAppSelector(getCms);

  const dispatch = useAppDispatch();
  const [GetCms] = useLazyGetCmsQuery();
  const [loading, setLoading] = useState(false);

  const fetchCmsData = async () => {
    try {
      setLoading(true);
      const response = await GetCms({}).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        dispatch(resetCms({ cms: response?.data }));
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCmsData();
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className="common_policy privicy">
        <div className="container">
          <div className="privt_one">
            <h1>{translation.footer.privacy}</h1>
            <p
              className={getCmsData ? "about_txt" : "about_txt2"}
              dangerouslySetInnerHTML={{
                __html: getCmsData
                  ? language === "ar"
                    ? getCmsData?.privacyPolicy_ar
                    : getCmsData?.privacyPolicy
                  : "",
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
