import React, { useEffect, useState } from "react";
import "./ServerMaintenance.scss";

import { STORAGE_KEYS, getFromStorage } from "../../helpers";

import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

import { useLazyGetCmsQuery } from "../../service/Cms";

const ServerMaintainance = () => {
  const translation = useTranslation() as any;
  const language = getFromStorage(STORAGE_KEYS.language);
  const navigate = useNavigate();

  const [GetCms] = useLazyGetCmsQuery();

  const fetchCmsData = async () => {
    try {
      const response = await GetCms({}).unwrap();
      if (response?.statusCode === 200) {
        navigate("/");
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element
    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      const isArabic = language === "ar";
      // Remove the opposite class before adding the new one
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };

  useEffect(() => {
    change();
  }, [language]);

  // useEffect(() => {
  //   fetchCmsData();
  // }, []);

  return (
    <div className="server_page">
      <div className="container server_main">
        <div className="server_text">
          <h1>
            {translation.Globals.we_are || ""} <pre />
            {translation.Globals.under_maintenance || ""}
          </h1>
          <p>{translation.Globals.apologies || ""}</p>
          <div className="btn_server">
            <Button
              value={translation.Globals.refresh || ""}
              onClick={fetchCmsData}
            />
          </div>
        </div>
        <div className="server_image">
          <figure>
            <img
              src="/static/images/server_maintenance.png"
              alt="server_maintenance"
            />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default ServerMaintainance;
