import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Dispatch, SetStateAction, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";

type SearchProps = {
  placeholder: string;
  value: string;
  onChange: any;
  inputRef: any;
  onCross?: () => void;
  searchTerm: string;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  onClick?: () => void;
  handleKeyDown?: (e: any) => void;
};
const SearchBar = ({
  placeholder,
  value,
  onChange,
  inputRef,
  onCross,
  setDebouncedSearchTerm,
  searchTerm,
  onClick,
  handleKeyDown,
}: SearchProps) => {
  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  return (
    <Box className="search_bar">
      <TextField
        inputRef={inputRef}
        value={value}
        autoComplete="off"
        onChange={onChange}
        hiddenLabel
        placeholder={placeholder}
        className="srch_inpt"
        fullWidth
        variant="outlined"
        onClick={onClick}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="search_icon">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" className="cross_btn">
              {value ? (
                <ClearIcon className="cross_svg" onClick={onCross} />
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
