import { END_POINTS } from "../helpers";
import { CommonBody, OtpResponse } from "../types/General";
import type { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostForgotPasswordResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostVerifyOtpResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PostResetPasswordBody = {
  password?: string;
};

type PostResetPasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PostChangePasswordBody = {
  oldPassword?: string;
  password?: string;
};

type PostChangePasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type GetProfileResponse = {
  statusCode: number;
  code?: number;
  data?: any;
};

type PutUpdateProfileBody = {
  email: string;
  name: string;
  countryCode: string;
  phone: string;
  address: string;
  image?: string;
};

type PutUpdateProfileResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type CommonResponse = {
  statusCode: number;
  message?: string;
  data?: any;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postSignUp: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),
    postVerifyOtp: builder.mutation<
      CommonResponseType & { data: OtpResponse },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.verifyotp,
        method: "POST",
        body,
      }),
    }),
    postLogin: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postForgotPassword: builder.mutation<
      PostForgotPasswordResponse,
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),

    postSetProfile: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.profile,
        method: "POST",
        body,
      }),
    }),
    postResetPassword: builder.mutation<PostResetPasswordResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),
    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.changepassword,
        method: "POST",
        body,
      }),
    }),
    getProfile: builder.query<GetProfileResponse, {}>({
      query: () => ({
        url: END_POINTS.getProfile,
        method: "GET",
      }),
    }),
    // getCms: builder.query<CommonResponse, {}>({
    //   query: () => ({
    //     url: END_POINTS.cms,
    //     method: "GET",
    //   }),
    // }),
    justSendOtp: builder.mutation<PostForgotPasswordResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.justSendOtp,
        method: "POST",
        body,
      }),
    }),
    justVerifyOtp: builder.mutation<
      CommonResponseType & { data: OtpResponse },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.justVerifyOtp,
        method: "POST",
        body,
      }),
    }),
    logoutApi: builder.mutation<CommonResponse, {}>({
      query: () => ({
        url: END_POINTS.logout,
        method: "POST",
      }),
    }),
    deleteAccount: builder.mutation<CommonResponse, {}>({
      query: () => ({
        url: END_POINTS.deleteAccount,
        method: "POST",
      }),
    }),
    postSocialLogin: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.socialLogin,
        method: "POST",
        body,
      }),
    }),
    updateGuestProfile: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.updateGuestProfile,
        method: "POST",
        body,
      }),
    }),
    postSellerForm: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.sellerForm,
        method: "POST",
        body,
      }),
    }),
    sellerSendOtp: builder.mutation<PostForgotPasswordResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.sellerSendOtp,
        method: "POST",
        body,
      }),
    }),
    sellerVerifyOtp: builder.mutation<
      CommonResponseType & { data: OtpResponse },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.sellerVerifyotp,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostVerifyOtpMutation,
  usePostSetProfileMutation,
  usePostForgotPasswordMutation,
  usePostResetPasswordMutation,
  usePostChangePasswordMutation,
  usePostSignUpMutation,
  useLazyGetProfileQuery,
  useLogoutApiMutation,
  useJustSendOtpMutation,
  useJustVerifyOtpMutation,
  useDeleteAccountMutation,
  usePostSocialLoginMutation,
  useUpdateGuestProfileMutation,
  usePostSellerFormMutation,
  useSellerSendOtpMutation,
  useSellerVerifyOtpMutation,
} = authApi;
