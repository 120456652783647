import { Dispatch, SetStateAction } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, InputField } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import "react-phone-input-2/lib/bootstrap.css";

import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";

import { errorToast, successToast } from "../../helpers";
import { usePostChangePasswordMutation } from "../../service/Auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

import useTranslation from "../../hooks/Translation";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
};

const ChangePassword = ({ closeModal, setPhone }: props) => {
  const translation = useTranslation() as any;
  const dispatch = useAppDispatch();

  const [changePassword] = usePostChangePasswordMutation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .label(translation.Auth.change_password.old_pass)
        .required(translation.validations.old_pass_required),

      newPassword: Yup.string()
        .label(translation.Auth.change_password.new_pass)
        .required(translation.validations.new_pass_required)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.password_validation
        )
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          translation.validations.new_pass_validation
        ),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], translation.validations.password_match)
        .required(translation.validations.required_field),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let data = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await changePassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(
            response?.message || translation.toast_messages.password_updated
          );
          closeModal();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <div>
      <div className="AuthWrap">
        <div className="cross ">
          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <h2 style={{ marginBottom: "15px" }}>
          {translation.Auth.change_password.change}
        </h2>

        <form onSubmit={formik.handleSubmit}>
          <InputField
            placeholder={translation.Auth.change_password.old_pass}
            password
            name="oldPassword"
            id="oldPassword"
            value={formik.values.oldPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />
          <InputField
            placeholder={translation.Auth.change_password.new_pass}
            password
            name="newPassword"
            id="newPassword"
            value={formik.values.newPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <InputField
            placeholder={translation.Auth.Sign_up.confirm_password}
            password
            name="confirmPassword"
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />

          <Button
            value={translation.Globals.submit}
          />
        </form>
      </div>
      <div style={{ marginBottom: 20 }} />
    </div>
  );
};

export default ChangePassword;
