import { END_POINTS } from "../helpers";
import { AddressRes, CardRes, CommonBody } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const manageAddressApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCard: builder.query<
      CommonResponseType & { data: { data: CardRes[] } },
      {}
    >({
      query: () => ({
        url: END_POINTS.getCard,
        method: "GET",
      }),
    }),
    saveCard: builder.mutation<
      CommonResponseType & { data: AddressRes },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.saveCard,
        method: "POST",
        body,
      }),
    }),
    getAddressById: builder.query<
      CommonResponseType & { data: AddressRes },
      { address_id: string }
    >({
      query: ({ address_id }) => ({
        url: `${END_POINTS.getMyAddress}/${address_id}`,
        method: "GET",
      }),
    }),

    updateAddress: builder.mutation<
      CommonResponseType & { data: AddressRes },
      { address_id: string; encryptedBody: CommonBody }
    >({
      query: ({ encryptedBody, address_id }) => ({
        url: `${END_POINTS.updateAddress}/${address_id}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    deleteCard: builder.mutation<
      CommonResponseType & { data: CardRes },
      CommonBody
    >({
      query: (body) => ({
        url: `${END_POINTS.deleteCard}`,
        method: "POST",
        body,
      }),
    }),
    postSelectAddress: builder.mutation<
      CommonResponseType & { data: AddressRes },
      { id: string; isDefault?: boolean }
    >({
      query: ({ id, isDefault }) => ({
        url: `${END_POINTS.selectAddress}/${id}?default=${isDefault}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyGetCardQuery,
  useSaveCardMutation,
  useDeleteCardMutation,
  useUpdateAddressMutation,
  useLazyGetAddressByIdQuery,
  usePostSelectAddressMutation,
} = manageAddressApi;
