import { Button } from "../../components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const SeasonalOffer2 = ({ offer2 }: any) => {
  return (
    <section className="ssnl_offr2 container">
      <div className="ofr2_ryt">
        <h2>{offer2?.heading || ""}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: offer2?.description || "",
          }}
        />
        <Button
          value="Shop now"
          onClick={() => {
            if (offer2?.button_link) {
              window.open(
                offer2?.button_link?.startsWith("http")
                  ? offer2?.button_link
                  : `https://${offer2?.button_link}`
              );
            }
          }}
        />
      </div>
      <div className="ofr2_lft">
        <figure>
          <LazyLoadImage
            src={offer2?.image}
            alt="Offer image"
            effect="blur"
            width={"100%"}
          />
        </figure>
      </div>
    </section>
  );
};

export default SeasonalOffer2;
