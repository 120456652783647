import { useEffect, useState } from "react";
import Layout from "../../layout";
import "./Cms.scss";
import { useLazyGetCmsQuery, usePostReviewMutation } from "../../service/Cms";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { Loader, errorToast, successToast } from "../../helpers";
import { Button, InputField } from "../../components";
import { TextField } from "@mui/material";
import { isString } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { getCms, resetCms } from "../../reducers/generalSlice";
import useTranslation from "../../hooks/Translation";

const ContactUs = () => {
  const translation = useTranslation() as any;
  const [postReview] = usePostReviewMutation();
  const getCmsData = useAppSelector(getCms);

  const dispatch = useAppDispatch();
  const [GetCms] = useLazyGetCmsQuery();
  const [loading, setLoading] = useState(false);

  const fetchCmsData = async () => {
    try {
      setLoading(true);
      const response = await GetCms({}).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        dispatch(resetCms({ cms: response?.data }));
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error, "error=========>");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.min_three),
      email: Yup.string()
        .required(translation.validations.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.validations.valid_email
        ),
      message: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.min_three),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let data = {
        name: formik.values.name,
        email: formik.values.email,
        message: formik.values.message,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postReview(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          formik.resetForm();
          successToast(translation.toast_messages.query_sent);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    fetchCmsData();
  }, []);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className="common_policy contact_us">
        <div className="container">
          <h1>{translation.footer.contact_us}</h1>
          <div className="admin_dtls">
            <p>
              {translation.Globals.email}:
              <a
                href={getCmsData ? `mailto:${getCmsData?.email}` : ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{getCmsData?.email || ""}</span>
              </a>
            </p>
            <p>
              {translation.Globals.phone_number}:
              <a
                href={
                  getCmsData
                    ? `tel:${getCmsData?.countryCode} ${getCmsData?.phone}`
                    : ""
                }
              >
                <span>
                  <span> {getCmsData?.countryCode || ""}</span>{" "}
                  <span>{getCmsData?.phone}</span>
                </span>
              </a>
            </p>
          </div>

          <div className="contact_frm">
            <h2>{translation.Globals.help}</h2>

            <form onSubmit={formik.handleSubmit} className="form_control">
              <div className="conact_flx">
                <div className="conact_one">
                  <InputField
                    placeholder={translation.Globals.full_name}
                    name="name"
                    id="name"
                    value={formik.values.name}
                    inputProps={{ maxLength: 40 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="conact_one">
                  <InputField
                    placeholder={translation.Globals.email}
                    name="email"
                    id="contact_email"
                    value={formik.values.email}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 10 }} />

              <TextField
                type="text"
                className="text_field text_area"
                hiddenLabel
                variant="outlined"
                placeholder={translation.Globals.message}
                multiline
                rows={4}
                fullWidth
                name="message"
                id="message"
                inputProps={{ maxLength: 300 }}
                value={formik.values.message}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.message && formik.errors.message}
              />

              <Button value={translation.Globals.contact_now} />
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
