import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import "../auth/Auth.scss";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch } from "../../hooks/store";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { usePostForgotPasswordMutation } from "../../service/Auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { Loader, errorToast, setToStorage, successToast } from "../../helpers";
import { Dispatch, SetStateAction } from "react";
import useTranslation from "../../hooks/Translation";

type Props = {
  closeModal: () => void;
  setEmailValue: Dispatch<SetStateAction<string>>;
};

const ForgotPassword = ({ closeModal, setEmailValue }: Props) => {
  const translation = useTranslation() as any;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const style = generateResponsiveStyle();
  const [postForgotPassword, { isLoading }] = usePostForgotPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translation.validations.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.validations.valid_email
        ),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let data = {
        key: formik.values.email,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postForgotPassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          setEmailValue(formik.values.email);
          successToast(response?.message || "");
          closeModal();
          dispatch(
            toggleAuthModal({
              isAuthModalVisible: true,
              ModalType: "verification",
            })
          );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  return (
    <div>
      <div className="AuthWrap">
        <Loader isLoad={isLoading} />
        <div className="cross ">
          {/* <KeyboardBackspaceIcon onClick={()=>}/> */}
          <CloseIcon onClick={() => closeModal()} />
        </div>
        <h2>{translation.Auth.Login.forgot_pas}</h2>
        <p>
          {translation.Auth.forgot_password.enter_email}
          {/* <span>admin@apptunix.com</span> */}
        </p>
        <form onSubmit={formik.handleSubmit}>
          <InputField
            placeholder={translation.Auth.Login.email_id}
            name="email"
            id="forgot_email"
            value={formik.values.email}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.email && formik.errors.email}
          />

          <Button
            value={translation.Globals.next}
            // onClick={handleSubmit}
          />
        </form>
        <div className="blacK" />
      </div>
    </div>
  );
};

export default ForgotPassword;
