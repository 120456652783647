import { useEffect, useState } from "react";
import Layout from "../../layout";
import { Skeleton } from "@mui/material";
import "../profile/profile.scss";
import { ChatDetail } from "../../features";
import {
  useLazyGetChatListQuery,
  usePostClearChatMutation,
} from "../../service/Product";
import { ChatHistoryResponse, ChatResponse } from "../../types/General";

import useAuth from "../../hooks/useAuth";
import moment from "moment";
import { STORAGE_KEYS, getFromStorage, successToast } from "../../helpers";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import "moment/locale/ar";
import { socket } from "../../utils/socket";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { GoogleTranslateScript } from "../../utils/GoogleTranslateScript";

let rmid = "";
const Chat = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  moment.locale("en");

  const translation = useTranslation() as any;
  const location = useLocation();
  const { state } = location;

  const userData = useAuth();

  const [tab, setTab] = useState(0);
  const [text, setText] = useState("");
  const [chats, setChats] = useState<ChatResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [chatHistory, setChatHistory] = useState<ChatHistoryResponse[]>([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedReceiverId, setSelectedReceiverId] = useState("");
  const [selectedReceiverName, setSelectedReceiverName] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [selectedReceiverImage, setSelectedReceiverImage] = useState("");
  const [chatDeleted, setChatDeleted] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [openMobileChat, setOpenMobileChat] = useState(false);
  const [productLang, setProductLang] = useState<number>(0);
  const [translatedProductName, setTranslatedProductName] = useState<string[]>(
    []
  );

  const [getChatList] = useLazyGetChatListQuery();
  const [clearChat] = usePostClearChatMutation();

  const targetLanguage = language === "ar" ? "ar" : "en";

  // const handleTranslate = async () => {
  //   const productNameArray = chats
  //     ?.map((item) => item?.productId?.name)
  //     .filter(Boolean);

  //   if (productNameArray.length > 0) {
  //     const translatedTexts = await Promise.all(
  //       productNameArray.map(async (productName) => {
  //         const translatedText = await GoogleTranslateScript(
  //           productName,
  //           targetLanguage
  //         );
  //         return translatedText;
  //       })
  //     );
  //     setTranslatedProductName(translatedTexts);
  //   }
  // };

  const deleteChat = async () => {
    try {
      const response = await clearChat({
        roomId: selectedRoomId || "",
      }).unwrap();

      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.chat_deleted);
        try {
          const response = await getChatList({
            roomId: selectedRoomId,
            filter: tab,
          }).unwrap();

          if (response?.statusCode === 200) {
            setChatHistory(response?.data?.chat || []);
            scrollToBottom();
          }
        } catch (error: any) {}
        setChatDeleted(true);
        setSelectedProductId("");
        setSelectedReceiverId("");
        setSelectedRoomId("");
      }
    } catch (error: any) {}
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      const ele = document.getElementById("scrollTo");
      ele?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 100);
  };

  const getChatHistory = async () => {
    setLoading(true);
    try {
      const response = await getChatList({
        roomId: "",
        filter: tab,
      }).unwrap();

      if (response?.statusCode === 200) {
        setChats(response?.data?.chat || []);
        setLoading(false);
        if (state && (state.type === "notification" || state.type === "chat")) {
          setLoading(true);
          try {
            const response = await getChatList({
              roomId: state?.roomId ? state?.roomId : "",
              filter: tab,
            }).unwrap();

            if (response?.statusCode === 200) {
              setChatHistory(response?.data?.chat || []);
              scrollToBottom();
              setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
          }
        }
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatHistory();
    scrollToBottom();
  }, [tab, chatDeleted]);

  const handleChatClick = async (item: ChatResponse) => {
    setChatDeleted(false);
    setSelectedProductId(item?.productId?._id);
    setSelectedReceiverId(item?.userId?._id);
    setSelectedRoomId(item?.roomId);
    rmid = item?.roomId;
    setSelectedReceiverName(item?.userId?.fullName);
    setSelectedReceiverImage(item?.userId?.image);
    setSelectedProductName(item?.productId?.name);
    setProductLang(item?.productId?.lang);

    try {
      const response = await getChatList({
        roomId: item?.roomId ? item?.roomId : "",
        filter: tab,
      }).unwrap();

      if (response?.statusCode === 200) {
        setChatHistory(response?.data?.chat || []);
        scrollToBottom();
      }
    } catch (error: any) {
      // setLoading(false);
    }
  };
  const joinRoom = () => {
    const data = {
      productId: selectedProductId,
      senderId: userData?._id,
      receiverId: selectedReceiverId,
    };

    socket?.emit("connectToChat", data);
    socket?.on("connectToChatOk", async (message: any) => {
      // console.log(message, "===room join====");
    });
  };

  const leaveRoom = async () => {
    const data = {
      roomId: rmid,
    };

    socket?.emit("disConnectToChat", data);
    socket?.on("disConnectToChatOk", async (message: any) => {
      // console.log(message, "===room left====");
    });
  };

  const sendMessage = async () => {
    const body = {
      senderId: userData?._id,
      receiverId: selectedReceiverId,
      productId: selectedProductId,
      type: "TEXT",
      message: text,
    };

    const data = {
      type: "TEXT",
      senderId: userData?._id,
      text: text,
      productId: selectedProductId,
      createdAt: new Date().toISOString(),
      product: selectedProductName,
      roomId: rmid,
    } as ChatHistoryResponse;

    if (socket) {
      socket?.emit("sendMessage", body);
      if (body) {
        setChatHistory([...chatHistory, data]);
        setText("");
        scrollToBottom();
      }
    }
  };

  const recieveChat = async () => {
    if (socket) {
      socket.on("receiveMessage", async (data: any) => {
        if (data?.senderId === userData?._id) {
        } else {
          const body = {
            _id: data?._id,
            type: "TEXT",
            senderId: data?.senderId || "",
            text: data?.text || "",
            text_ar: data?.text_ar || "",
            text2: data?.text2 || "",
            forLang: data?.forLang || false,
            text_ar2: data?.text_ar2 || "",
            productId: data?.productId || "",
            createdAt: data?.createdAt,
            offer: data?.offer,
            product: data?.product || "",
            roomId: data?.roomId || "",
            amount: data?.amount || 0,
          } as ChatHistoryResponse;
          setChatHistory((history) => [...history, body]);
          setText("");
          scrollToBottom();
        }
      });
    }
  };

  useEffect(() => {
    if (selectedRoomId?.length) {
      joinRoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoomId]);

  useEffect(() => {
    if (state && (state.type === "notification" || state.type === "chat")) {
      rmid = state.roomId;
      setSelectedRoomId(state.roomId);
      setSelectedReceiverId(state.senderId);
      setSelectedReceiverImage(state.senderImage);
      setSelectedReceiverName(state.senderName);
      setSelectedProductName(state.productName);
      setSelectedProductId(state.productId);
      setProductLang(state.productLang);
    } else {
      rmid = "";
      setSelectedRoomId("");
      setSelectedReceiverId("");
      setSelectedReceiverImage("");
      setSelectedReceiverName("");
      setSelectedProductName("");
      setProductLang(0);
    }
  }, [chats]);

  useEffect(() => {
    if (rmid) {
      setOpenMobileChat(true);
    }
  }, [rmid]);

  useEffect(() => {
    recieveChat();
  }, []);

  // useEffect(() => {
  //   handleTranslate();
  // }, [language, chats]);

  return (
    <Layout>
      <div className="wrapCls" style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="pMain cht_revrs" style={{ alignItems: "normal" }}>
            {loading ? (
              <div
                className={openMobileChat ? "chats_mob pLeft" : "pLeft"}
                style={{ padding: 10 }}
              >
                <div className="tabs">
                  <h3
                    className={tab === 0 ? "active" : ""}
                    onClick={() => setTab(0)}
                  >
                    {translation.Globals.all}
                  </h3>
                  <h3
                    className={tab === 1 ? "active" : ""}
                    onClick={() => setTab(1)}
                  >
                    {translation.chat.buying}
                  </h3>
                  <h3
                    className={tab === 2 ? "active" : ""}
                    onClick={() => setTab(2)}
                  >
                    {translation.chat.selling}
                  </h3>
                </div>
                <ul className="chat">
                  {Array.from({ length: 3 }, (_, index) => (
                    <li key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <figure
                          style={{
                            marginRight: language === "ar" ? "0" : "10px",
                            marginLeft: language === "ar" ? "10px" : "0px",
                          }}
                        >
                          <Skeleton
                            variant="circular"
                            height={"100%"}
                            width={"100%"}
                            animation="wave"
                          />
                        </figure>
                        <h5
                          style={{
                            right: language === "ar" ? "unset" : "10px",
                            left: language === "ar" ? "10px" : "unset",
                          }}
                        >
                          <Skeleton
                            variant="text"
                            width={"50px"}
                            style={{ fontSize: "1rem" }}
                            animation="wave"
                          />
                        </h5>
                        <div className="contnt">
                          <h4
                            className="chat_prodct"
                            style={{ paddingBottom: "4px", color: "#820000" }}
                          >
                            <Skeleton
                              variant="text"
                              width={"120px"}
                              style={{ fontSize: "1rem" }}
                              animation="wave"
                            />
                          </h4>
                          <h3 className="user_chat">
                            <Skeleton
                              variant="text"
                              width={"120px"}
                              style={{ fontSize: "1rem" }}
                              animation="wave"
                            />
                          </h3>
                          <p className="chat_msg">
                            <Skeleton
                              variant="text"
                              width={"120px"}
                              style={{ fontSize: "1rem" }}
                              animation="wave"
                            />
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}{" "}
                </ul>
              </div>
            ) : (
              <div
                className={openMobileChat ? "chats_mob pLeft" : "pLeft"}
                style={{ padding: 10 }}
              >
                <div className="tabs">
                  <h3
                    className={tab === 0 ? "active" : ""}
                    onClick={() => setTab(0)}
                  >
                    {translation.Globals.all}
                  </h3>
                  <h3
                    className={tab === 1 ? "active" : ""}
                    onClick={() => setTab(1)}
                  >
                    {translation.chat.buying}
                  </h3>
                  <h3
                    className={tab === 2 ? "active" : ""}
                    onClick={() => setTab(2)}
                  >
                    {translation.chat.selling}
                  </h3>
                </div>
                <ul className="chat">
                  {chats?.length ? (
                    chats?.map((item, index) => (
                      <li
                        key={item?._id}
                        onClickCapture={() => {
                          leaveRoom();
                          handleChatClick(item);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <figure
                            style={{
                              marginRight: language === "ar" ? "0" : "10px",
                              marginLeft: language === "ar" ? "10px" : "0px",
                            }}
                          >
                            <LazyLoadImage
                              alt="user_placeholder"
                              src={
                                item?.userId?.image ||
                                "/static/images/user_placeholder.png"
                              }
                              effect="blur"
                              height={"100%"}
                              width={"100%"}
                            />
                          </figure>
                          <h5
                            style={{
                              right: language === "ar" ? "unset" : "10px",
                              left: language === "ar" ? "10px" : "unset",
                            }}
                          >
                            {moment(item?.lastMessage?.updatedAt).format(
                              "hh:mm A"
                            )}
                          </h5>
                          <div className="contnt">
                            <h4
                              className="chat_prodct"
                              style={{ paddingBottom: "4px", color: "#820000" }}
                            >
                              {/* {language === "en" ? (
                                <>
                                  {item?.productId?.lang === 1
                                    ? item?.productId?.name
                                    : translatedProductName[index] || ""}
                                </>
                              ) : (
                                <>
                                  {item?.productId?.lang === 2
                                    ? item?.productId?.name
                                    : translatedProductName[index] || ""}
                                </>
                              )}{" "} */}

                              {item?.productId?.name || "-"}
                            </h4>
                            <h3 className="user_chat">
                              {item?.userId?.fullName || ""}
                            </h3>
                            <p className="chat_msg">
                              {item?.lastMessage?.forLang &&
                              item?.lastMessage?.senderId !== userData?._id
                                ? language === "ar" && item.lastMessage?.forLang
                                  ? item?.lastMessage?.text_ar2
                                  : item?.lastMessage?.text2
                                : language === "ar" && item.lastMessage?.forLang
                                ? item?.lastMessage?.text_ar
                                : item?.lastMessage?.text}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="no_Chat">{translation.chat.no_chats}</div>
                  )}
                </ul>
              </div>
            )}
            {loading ? (
              <div className={openMobileChat ? "mobile_chat pRyt" : "pRyt"}>
                <Skeleton height={"100%"} width={"100%"} variant="rounded" />
              </div>
            ) : (
              <div
                className={openMobileChat ? "mobile_chat pRyt" : "pRyt"}
                style={{ padding: 30 }}
              >
                {rmid && !chatDeleted ? (
                  <ChatDetail
                    chatHistory={chatHistory}
                    setText={setText}
                    text={text}
                    sendMessage={sendMessage}
                    selectedProductName={selectedProductName}
                    selectedReceiverName={selectedReceiverName}
                    selectedReceiverImage={selectedReceiverImage}
                    selectedReceiverId={selectedReceiverId}
                    deleteChat={deleteChat}
                    setChatHistory={setChatHistory}
                    tab={tab}
                    setOpenMobileChat={setOpenMobileChat}
                    leaveRoom={leaveRoom}
                    productLang={productLang}
                  />
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <figure
                      style={{
                        height: "70px",
                        width: "60px",
                        paddingBottom: "10px",
                      }}
                    >
                      <LazyLoadImage
                        alt="chat_img"
                        src="/static/images/chat_img.png"
                        effect="blur"
                        height={"100%"}
                        width={"100%"}
                      />
                    </figure>
                    {translation.chat.go_ahead}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Chat;
