import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../layout";
import { Box, Button, Modal, Skeleton, TextField } from "@mui/material";
import "./details.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CropFreeIcon from "@mui/icons-material/CropFree";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import CloseIcon from "@mui/icons-material/Close";
import {
  useChangeFavStatusMutation,
  useDeletePropertyByIdMutation,
  useLazyGetMyBoosterQuery,
  useLazyGetProductByIdQuery,
  useLazyGetRelatedProductsQuery,
  usePostMakeOfferMutation,
  usePostReportProductMutation,
} from "../../service/Product";
import {
  CommonBody,
  ProductDetailData,
  RecentProduct,
  boosterPlans,
} from "../../types/General";

import {
  Button as ButtonComponent,
  ProductCard,
  ProductCardSkeleton,
} from "../../components";

import moment from "moment";

import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import useAuth from "../../hooks/useAuth";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { RWebShare } from "react-web-share";
import useTranslation from "../../hooks/Translation";

import "moment/locale/ar";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DocumentViewer from "../../components/DocumentViewer";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { getTempToken } from "../../reducers/authSlice";
import {
  GoogleTranslateScript,
  translateArray,
} from "../../utils/GoogleTranslateScript";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplaySpeed: 3000,
};

const magnifierHeight = 500;
const magnifieWidth = 500;
const zoomLevel = 2;

const ProductDetail = () => {
  moment.locale("en");

  const language = getFromStorage(STORAGE_KEYS.language);

  const translation = useTranslation() as any;
  const location = useLocation();

  const { state } = location;
  const user = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const tempToken = useAppSelector(getTempToken);
  const token = getFromStorage(STORAGE_KEYS.token);

  const style = generateResponsiveStyle();
  const [openImage, setOpenImage] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [offerAmount, setOfferAmount] = useState<any>();
  const [productDetail] = useLazyGetProductByIdQuery();
  const [productData, setProductData] = useState<ProductDetailData>();
  const [selectedImage, setSelectedImage] = useState(
    productData?.images[0] || ""
  );

  const [openReport, setOpenReport] = useState(false);
  const [report, setReport] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [deleteProduct] = useDeletePropertyByIdMutation();
  const [postMakeOffer, GetMakeOfferData] = usePostMakeOfferMutation();
  const [reportProduct, GetReportData] = usePostReportProductMutation();
  const [getRelatedProducts] = useLazyGetRelatedProductsQuery();

  const targetLanguage = language === "ar" ? "ar" : "en";

  const [translatedProductName, setTranslatedProductName] = useState("");

  const [translatedDynamicFields, setTranslatedDynamicFields] = useState<any[]>(
    []
  );

  const [relatedProducts, setRelatedProducts] = useState<RecentProduct[]>([]);
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const [showMagnifier, setShowMagnifier] = useState(false);
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);

  const [favItems, setFavItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const fav_data = relatedProducts
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  // const handleTranslate = async () => {
  //   if (productData?.name) {
  //     const translatedText = await GoogleTranslateScript(
  //       productData?.name,
  //       targetLanguage
  //     );
  //     setTranslatedProductName(translatedText);
  //   }

  //   if (productData?.dynamicFields) {
  //     const translatedArray = await translateArray(
  //       productData?.dynamicFields,
  //       targetLanguage
  //     );

  //     setTranslatedDynamicFields([...translatedArray?.filter((ele) => !!ele)]);
  //   }
  // };

  // useEffect(() => {
  //   handleTranslate();
  // }, [language, productData]);

  const handleMakeOffer = async (id: string) => {
    const data = {
      amount: Number(offerAmount),
    };

    if (!offerAmount) {
      errorToast(translation.toast_messages.enter_offer);
      return;
    }
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await postMakeOffer({
        productId: id,
        encryptedBody,
      }).unwrap();
      if (res?.statusCode === 200) {
        setRoomId(res?.data?.chatRoomId);
        setOpenOfferModal(false);
        setOfferAmount(null);
        successToast(res?.message || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const handleReportClick = () => {
    if (user && user?.isProfileComplete) {
      setOpenReport(true);
    } else if (user && !user?.isProfileComplete) {
      dispatch(
        toggleAuthModal({
          isAuthModalVisible: true,
          ModalType: "profileSetup",
        })
      );
    } else {
      dispatch(
        toggleAuthModal({
          isAuthModalVisible: true,
          ModalType: "login",
        })
      );
    }
  };

  const handleReportProduct = async (id: string | undefined) => {
    setError(true);
    if (!report) {
      return;
    }
    let data = {
      reason: report,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const response = await reportProduct({
        product_id: id,
        encryptedBody,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.report_sent);
        setReport("");
        setError(false);
        setOpenReport(false);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      setOpenReport(false);
      setReport("");
      console.log(error);
    }
  };

  const handleDeleteProduct = async (id: string) => {
    try {
      const res = await deleteProduct({ id }).unwrap();
      if (res?.statusCode === 200) {
        successToast(translation.toast_messages.product_deleted || "");
        navigate("/", { replace: true });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getProductDetail = async (id: string | undefined) => {
    setLoading(true);
    try {
      const response = await productDetail({
        product_id: id,
        searchHistory: state === "saveSearchHistory" ? true : false,
      }).unwrap();

      if (response?.statusCode === 200) {
        setLoading(false);
        if (response?.data?.isDeleted) {
          navigate("/");
        }
        setProductData(response?.data);
        try {
          const res = await getRelatedProducts({
            subCategoryId: response?.data?.subCategoryId,
            productId: response?.data?._id,
          }).unwrap();

          if (res?.statusCode === 200) {
            setRelatedProducts(res?.data?.relatedProducts || []);
          }
        } catch (error: any) {
          setLoading(false);
          errorToast(error?.data?.message || "");
        }

        setRoomId(response?.data?.chatRoomId || "");
        setSelectedId(response?.data?._id);
        setSelectedImage(response?.data?.images[0]);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };
  const [changeFavStatus] = useChangeFavStatusMutation();
  const [myBooster] = useLazyGetMyBoosterQuery();
  const [myBoosterState, setMyBoosterState] = useState<boosterPlans>();

  const getMyBooster = async () => {
    try {
      const result = await myBooster({}).unwrap();
      if (result?.statusCode === 200) {
        setMyBoosterState(result?.data || null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useAppDispatch();
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const expiryDate = myBoosterState?.expiryDate;

    if (!expiryDate) {
      // Handle the case where expiryDate is undefined
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const expiryTime = new Date(expiryDate).getTime();
    const timeRemaining = expiryTime - now;

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const formatTime = (value: any) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  useEffect(() => {
    setTimeRemaining(calculateTimeRemaining());
  }, [myBoosterState?.expiryDate]);

  const handleFav = async (id: string | undefined) => {
    const data = {
      productId: id,
      isFav: !productData?.isFav,
    };

    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      const response = await changeFavStatus(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        getProductDetail(id);
        successToast(response?.message || "");
      } else {
        errorToast(translation.toast_messages.try_again);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id && (token || tempToken)) {
      getProductDetail(id);
      getMyBooster();
    }
  }, [id, tempToken]);

  useEffect(() => {
    checkFavProperties();
  }, [relatedProducts]);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [id]);

  return (
    <Layout>
      <Loader
        isLoad={GetReportData?.isLoading || GetMakeOfferData?.isLoading}
      />
      <div className="wrapCls">
        <div className="container">
          <h1 className="prdct_dtl_hdng">
            {translation.product.product_detail}
          </h1>
          <div className="detail_main">
            <div className="dLeft">
              {loading || selectedImage === "" ? (
                <Skeleton
                  className=" web_images"
                  variant="rounded"
                  animation="wave"
                  height={"600px"}
                />
              ) : selectedImage ? (
                <div className="prdct_img_sc web_images">
                  <div
                    style={{
                      position: "relative",
                      height: 600,
                      width: 500,
                    }}
                  >
                    <img
                      src={selectedImage}
                      style={{ height: 600, width: 500 }}
                      alt={"img"}
                      onMouseEnter={(e) => {
                        // update image size and turn-on magnifier
                        const elem = e.currentTarget;
                        const { width, height } = elem.getBoundingClientRect();
                        setSize([width, height]);
                        setShowMagnifier(true);
                      }}
                      onMouseMove={(e) => {
                        // update cursor position
                        const elem = e.currentTarget;
                        const { top, left } = elem.getBoundingClientRect();

                        // calculate cursor position on the image
                        const x = e.pageX - left - window.pageXOffset;
                        const y = e.pageY - top - window.pageYOffset;
                        setXY([x, y]);
                      }}
                      onMouseLeave={() => {
                        // close magnifier
                        setShowMagnifier(false);
                      }}
                    />
                    <div
                      className="magnifier_show"
                      style={{
                        display: showMagnifier ? "" : "none",
                        position: "absolute",

                        // prevent magnifier blocks the mousemove event of img
                        pointerEvents: "none",
                        // set size of magnifier
                        height: `${magnifierHeight}px`,
                        width: `${magnifieWidth}px`,

                        top: 0,

                        opacity: "1", // reduce opacity so you can verify position
                        border: "1px solid lightgray",
                        backgroundColor: "white",
                        backgroundImage: `url('${selectedImage}')`,
                        backgroundRepeat: "no-repeat",

                        //calculate zoomed image size
                        backgroundSize: `${imgWidth * zoomLevel}px ${
                          imgHeight * zoomLevel
                        }px`,

                        //calculate position of zoomed image.
                        backgroundPositionX: `${
                          -x * zoomLevel + magnifieWidth / 2
                        }px`,
                        backgroundPositionY: `${
                          -y * zoomLevel + magnifierHeight / 2
                        }px`,
                      }}
                    ></div>
                  </div>

                  <CropFreeIcon
                    className="mgnify_icn"
                    onClick={() => {
                      setOpenImage(true);
                    }}
                  />
                </div>
              ) : null}

              <div className="mobile_images">
                {loading || selectedImage === "" ? (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    className="prdct_img_sc"
                    height={"400px"}
                    style={{
                      background: "rgba(0, 0, 0, 0.11)",
                    }}
                  />
                ) : (
                  <>
                    {productData?.images?.length ? (
                      <Slider {...settings}>
                        {productData?.images?.map((item) => {
                          return (
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <figure
                                className="prdct_img_sc mbl_vwhw"
                                onClick={() => {
                                  setOpenImage(true);
                                }}
                              >
                                <LazyLoadImage
                                  src={item}
                                  alt="Product"
                                  effect="blur"
                                  width={"100%"}
                                  height={"100%"}
                                />
                              </figure>
                              <CropFreeIcon
                                className="mgnify_icn"
                                onClick={() => {
                                  setOpenImage(true);
                                }}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    ) : null}
                  </>
                )}
              </div>

              <div className="p_Imgs">
                {loading || selectedImage === ""
                  ? Array.from({ length: 6 }, (_, index) => (
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={70}
                        width={"15%"}
                      />
                    ))
                  : productData?.images?.length
                  ? productData?.images?.map((item) => (
                      <figure onClick={() => handleImageClick(item)}>
                        <LazyLoadImage
                          src={item}
                          alt="Product"
                          effect="blur"
                          width={"100%"}
                          height={"100%"}
                        />
                      </figure>
                    ))
                  : null}
              </div>
              <div className="prdct_dscrptn">
                <h2
                  style={{
                    fontSize: "22px",
                    fontWeight: 500,
                    color: "#820000",
                  }}
                >
                  {translation.profile.description}
                </h2>
                <p
                  style={{
                    lineHeight: "24px",
                    paddingTop: "6px",
                    wordBreak: "break-all",
                  }}
                >
                  {productData?.description &&
                  !isReadMore &&
                  productData?.description?.length >= 450
                    ? productData?.description?.slice(0, 450) + "..."
                    : productData?.description}
                </p>
                {productData?.description &&
                  productData?.description?.length >= 450 && (
                    <p
                      onClick={toggleReadMore}
                      style={{
                        wordBreak: "break-all",
                        textAlign: "center",
                        color: "#820000",
                        fontWeight: "bold",
                        paddingTop: "6px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      {isReadMore ? "SHOW LESS" : "READ MORE"}
                    </p>
                  )}
              </div>
            </div>
            <div className="dRyt">
              <>
                {loading || selectedImage === "" ? (
                  <div className="rightCard">
                    <div className="auto_ryt">
                      <h3
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ fontSize: "1rem", width: "100px" }}
                          />
                        }
                      </h3>
                      <h2>
                        {" "}
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ fontSize: "1rem", width: "200px" }}
                        />
                      </h2>
                      <p className="p2">
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ fontSize: "1rem", width: "200px" }}
                        />
                      </p>
                      <p>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ fontSize: "1rem", width: "200px" }}
                        />
                      </p>
                    </div>
                    <div className="auto_left">
                      <div className="fig">
                        <Skeleton
                          variant="circular"
                          animation="wave"
                          height={40}
                          width={40}
                          style={{ marginRight: "10px" }}
                        />

                        {/* <Skeleton
                          variant="circular"
                          animation="wave"
                          height={40}
                          width={40}
                        /> */}
                      </div>
                      <h2>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ fontSize: "1rem" }}
                        />
                      </h2>
                    </div>
                  </div>
                ) : (
                  <div className="rightCard">
                    <div className="mn_rightCard">
                      <div className="auto_ryt">
                        <h3
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {language === "ar"
                            ? productData?.category?.name_ar || ""
                            : productData?.category?.name || ""}
                          <figure
                            style={{
                              height: "40px",
                              width: "40px",
                            }}
                          >
                            {productData?.boosterApplied ? (
                              <LazyLoadImage
                                src="/static/images/booster.png"
                                alt="booster"
                                effect="blur"
                                width={"100%"}
                                height={"100%"}
                              />
                            ) : null}
                          </figure>
                        </h3>

                        <h2>
                          {" "}
                          {/* {language === "en" ? (
                            <>
                              {productData?.lang === 1
                                ? productData?.name
                                : translatedProductName}
                            </>
                          ) : (
                            <>
                              {productData?.lang === 2
                                ? productData?.name
                                : translatedProductName}
                            </>
                          )}{" "} */}
                          {productData?.name || "-"}
                        </h2>

                        <p className="p2">
                          {translation.product.posted_on}:{" "}
                          {moment(productData?.createdAt).format(
                            "MMM Do YYYY"
                          ) || ""}
                        </p>
                        {productData?.userId === user?._id &&
                        user?.boosterBought ? (
                          <div className="boosterValidity">
                            <p>
                              {`${"Boost"} (${formatTime(
                                timeRemaining.days
                              )}d : ${formatTime(
                                timeRemaining.hours
                              )}h : ${formatTime(timeRemaining.minutes)}m)` ||
                                ""}
                            </p>{" "}
                          </div>
                        ) : null}
                      </div>

                      <div className="auto_left">
                        <div className="fig">
                          <RWebShare
                            data={{
                              text: "Product",
                              url: `https://www.krafo.com/product-detail/${productData?._id}`, //live
                              // url: `https://dwmweb.appgrowthcompany.com/product-detail/${productData?._id}`, //dev

                              title: translation.product.share,
                            }}
                            onClick={() => console.log("Shared successfully!")}
                          >
                            <figure>
                              <LazyLoadImage
                                src="/static/images/share.svg"
                                alt="Product"
                                effect="blur"
                                width={"100%"}
                                height={"100%"}
                              />
                            </figure>
                          </RWebShare>

                          {user?._id ===
                          productData?.userId ? null : productData?.isFav ? (
                            <figure onClick={() => handleFav(productData?._id)}>
                              <LazyLoadImage
                                src="/static/images/filled_heart.svg"
                                alt="Product"
                                effect="blur"
                                width={"100%"}
                                height={"100%"}
                              />
                            </figure>
                          ) : (
                            <figure onClick={() => handleFav(productData?._id)}>
                              <LazyLoadImage
                                src="/static/images/heart.svg"
                                alt="Product"
                                effect="blur"
                                width={"100%"}
                                height={"100%"}
                              />
                            </figure>
                          )}
                        </div>
                        {productData?.price === 0 || !productData?.price ? (
                          <h2>{translation.Globals.askPrice || ""}</h2>
                        ) : (
                          <h2>
                            {translation.Globals.aed}{" "}
                            {productData?.price.toLocaleString("en-IN") || 0}
                          </h2>
                        )}

                        {/* <h2>
                          {translation.Globals.aed}{" "}
                          {productData?.price.toLocaleString("en-IN")}
                        </h2> */}
                      </div>
                    </div>
                  </div>
                )}

                {loading ? null : user?._id === productData?.userId ? null : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: "14px",
                        paddingTop: "6px",
                      }}
                      onClick={handleReportClick}
                    >
                      {translation.Globals.report_ad || ""}{" "}
                      <ReportGmailerrorredIcon sx={{ fontSize: "16px" }} />
                    </p>
                  </div>
                )}
              </>
              {loading || selectedImage === "" ? (
                <div className="rightCard2">
                  <div className="card_top">
                    <div className="cd_lft">
                      <figure>
                        <Skeleton
                          variant="circular"
                          height={"100%"}
                          width={"100%"}
                          animation="wave"
                        />
                      </figure>
                      <div
                        className="username"
                        style={{
                          marginLeft: language === "ar" ? "0" : "17px",
                          marginRight: language === "ar" ? "17px" : "0",
                        }}
                      >
                        <h3>
                          <Skeleton
                            variant="text"
                            width={150}
                            style={{ fontSize: "2rem" }}
                            animation="wave"
                          />
                        </h3>
                        <h4>
                          <Skeleton
                            variant="text"
                            width={100}
                            style={{ fontSize: "1rem" }}
                            animation="wave"
                          />
                        </h4>
                      </div>
                    </div>
                    <div className="cd_ryt">
                      <p>
                        <Skeleton
                          variant="text"
                          width={100}
                          style={{ fontSize: "1rem" }}
                          animation="wave"
                        />
                      </p>
                    </div>
                  </div>

                  <div className="card_btm">
                    <div style={{ width: "49%", height: "40px" }}>
                      <Skeleton
                        variant="rounded"
                        width={"100%"}
                        height={"100%"}
                        style={{
                          marginLeft: language === "ar" ? "10px" : "0",
                          marginRight: language === "ar" ? "0px" : "10px",
                          borderRadius: "40px",
                        }}
                      />
                    </div>
                    <div style={{ width: "49%", height: "40px" }}>
                      <Skeleton
                        variant="rounded"
                        width={"100%"}
                        height={"100%"}
                        style={{
                          marginLeft: language === "ar" ? "10px" : "0",
                          marginRight: language === "ar" ? "0px" : "10px",
                          borderRadius: "40px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="rightCard2">
                  {user?._id === productData?.userId ? null : (
                    <div className="card_top">
                      <div className="cd_lft">
                        <figure>
                          <LazyLoadImage
                            src={
                              productData?.user?.image ||
                              "/static/images/empty_user.png"
                            }
                            alt="empty_user"
                            onClick={() => {
                              user
                                ? navigate(
                                    `/view-profile/${productData?.userId}`
                                  )
                                : dispatch(
                                    toggleAuthModal({
                                      isAuthModalVisible: true,
                                      ModalType: "login",
                                    })
                                  );
                            }}
                            effect="blur"
                            width={"100%"}
                            height={"100%"}
                          />
                        </figure>
                        <div
                          className="username"
                          style={{
                            marginLeft: language === "ar" ? "0" : "17px",
                            marginRight: language === "ar" ? "17px" : "0",
                          }}
                        >
                          <h3
                            onClick={() => {
                              user
                                ? navigate(
                                    `/view-profile/${productData?.userId}`
                                  )
                                : dispatch(
                                    toggleAuthModal({
                                      isAuthModalVisible: true,
                                      ModalType: "login",
                                    })
                                  );
                            }}
                          >
                            {productData?.user?.fullName || ""}
                          </h3>
                          <h4>
                            {translation.product.member_since}{" "}
                            {moment(productData?.user?.createdAt).format(
                              "MMM YYYY"
                            ) || ""}
                          </h4>
                        </div>
                      </div>
                      <div className="cd_ryt">
                        <p
                          onClick={() => {
                            user
                              ? navigate(`/view-profile/${productData?.userId}`)
                              : dispatch(
                                  toggleAuthModal({
                                    isAuthModalVisible: true,
                                    ModalType: "login",
                                  })
                                );
                          }}
                        >
                          {translation.product.view_profile}
                        </p>
                      </div>
                    </div>
                  )}
                  {user?._id === productData?.userId ? (
                    <>
                      <div className="more_details">
                        {productData?.brand?.length ||
                        productData?.totalQuantity ? (
                          <h4> {translation.product.more_details}</h4>
                        ) : null}

                        {productData?.brand?.length ? (
                          <ul>
                            <li
                              style={{
                                fontSize: "16px",
                                paddingBottom: "6px",
                                paddingTop: "6px",
                              }}
                            >
                              <p>{translation.Globals.brand || ""}</p>
                              <p>{productData?.brand || ""}</p>
                            </li>
                          </ul>
                        ) : null}
                        {productData?.totalQuantity ? (
                          <li>
                            <p>{translation.Globals.minimum_quantity}</p>
                            <p>{productData?.totalQuantity}</p>
                          </li>
                        ) : null}

                        <ul>
                          {language === "ar"
                            ? productData?.dynamicFields?.map(
                                (
                                  item:
                                    | {
                                        [s: string]: React.ReactNode;
                                      }
                                    | ArrayLike<React.ReactNode>
                                ) => {
                                  return (
                                    <li>
                                      <p>{Object.keys(item)}</p>
                                      <p>{Object.values(item)}</p>
                                    </li>
                                  );
                                }
                              )
                            : productData?.dynamicFields?.map(
                                (
                                  item:
                                    | {
                                        [s: string]: React.ReactNode;
                                      }
                                    | ArrayLike<React.ReactNode>
                                ) => {
                                  return (
                                    <li>
                                      <p>{Object.keys(item)}</p>
                                      <p>{Object.values(item)}</p>
                                    </li>
                                  );
                                }
                              )}
                        </ul>
                      </div>
                      <div className="card_btm">
                        <Button
                          onClick={() =>
                            navigate(
                              `/add-product/${productData?.category?._id}/${productData?._id}`
                            )
                          }
                        >
                          {translation.product.edit_detail}
                        </Button>
                        <Button onClick={() => setOpenModal(true)}>
                          {translation.product.delete_product}
                        </Button>
                      </div>
                    </>
                  ) : user?._id !== productData?.userId ? (
                    <div className="card_btm">
                      <Button
                        onClick={() => {
                          user && user?.isProfileComplete
                            ? navigate("/chat", {
                                state: {
                                  type: "chat",
                                  roomId: roomId
                                    ? roomId
                                    : `chat_${productData?._id}_${user?._id}`,
                                  senderId: productData?.user?._id,
                                  senderImage: productData?.user?.image,
                                  senderName: productData?.user?.fullName,
                                  productName: productData?.name,
                                  productId: productData?._id,
                                  productLang: productData?.lang,
                                },
                              })
                            : user && !user?.isProfileComplete
                            ? dispatch(
                                toggleAuthModal({
                                  isAuthModalVisible: true,
                                  ModalType: "profileSetup",
                                })
                              )
                            : dispatch(
                                toggleAuthModal({
                                  isAuthModalVisible: true,
                                  ModalType: "login",
                                })
                              );
                        }}
                      >
                        <figure
                          style={{
                            marginLeft: language === "ar" ? "10px" : "0",
                            marginRight: language === "ar" ? "0px" : "10px",
                          }}
                        >
                          <LazyLoadImage
                            src="/static/images/chat.svg"
                            alt="Product"
                            effect="blur"
                            width={"100%"}
                            height={"100%"}
                          />
                        </figure>
                        {translation.Globals.chat}
                      </Button>
                      {productData?.makeAnOffer ? (
                        <Button
                          className="makeOffer"
                          onClick={() => {
                            user && user?.isProfileComplete
                              ? setOpenOfferModal(true)
                              : user && !user?.isProfileComplete
                              ? dispatch(
                                  toggleAuthModal({
                                    isAuthModalVisible: true,
                                    ModalType: "profileSetup",
                                  })
                                )
                              : dispatch(
                                  toggleAuthModal({
                                    isAuthModalVisible: true,
                                    ModalType: "login",
                                  })
                                );
                          }}
                          disabled={!productData?.makeAnOffer}
                        >
                          <figure
                            className="offr_img"
                            style={{
                              marginLeft: language === "ar" ? "10px" : "0",
                              marginRight: language === "ar" ? "0px" : "10px",
                            }}
                          >
                            <LazyLoadImage
                              src="/static/images/offer.svg"
                              alt="Product"
                              effect="blur"
                              width={"100%"}
                              height={"100%"}
                            />
                          </figure>
                          {translation.product.make_offer}
                        </Button>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}

              {user?._id !== productData?.userId &&
              (productData?.dynamicFields?.length ||
                productData?.totalQuantity) ? (
                <div className="more_Info">
                  <h4>{translation.product.more_details}</h4>
                  {productData?.brand?.length ? (
                    <ul>
                      <li
                        style={{
                          fontSize: "16px",
                          paddingBottom: "6px",
                          paddingTop: "6px",
                        }}
                      >
                        <p>{translation.Globals.brand || ""}</p>
                        <p>{productData?.brand || ""}</p>
                      </li>
                    </ul>
                  ) : null}
                  {productData?.totalQuantity ? (
                    <li>
                      <p>{translation.Globals.minimum_quantity}</p>
                      <p>{productData?.totalQuantity}</p>
                    </li>
                  ) : null}
                  {productData?.brand?.length ? (
                    <ul>
                      <li
                        style={{
                          fontSize: "16px",
                          paddingBottom: "6px",
                          paddingTop: "6px",
                        }}
                      >
                        <p>{translation.Globals.brand || ""}</p>
                        <p>{productData?.brand || ""}</p>
                      </li>
                    </ul>
                  ) : null}
                  <ul>
                    {productData?.dynamicFields
                      ?.filter((ele: any) => !!ele)
                      ?.map(
                        (
                          item:
                            | {
                                [s: string]: React.ReactNode;
                              }
                            | ArrayLike<React.ReactNode>
                        ) => {
                          return (
                            <li>
                              <p>{Object.keys(item)}</p>
                              <p>{Object.values(item)}</p>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>

          {loading || selectedImage === "" ? (
            <section className="prodcts_sc ">
              <div className="crds_sec">
                {Array.from({ length: 5 }, (_, index) => (
                  <ProductCardSkeleton key={index} />
                ))}
              </div>
            </section>
          ) : (
            <>
              {relatedProducts?.length ? (
                <section className="prodcts_sc whlt_sc">
                  <div className="prdct_hdngs ">
                    <h2>{translation.Globals.related_products}</h2>
                    {relatedProducts?.length > 7 ? (
                      <Link
                        to={{
                          pathname: `/products/${productData?.categoryId}`,
                          search: `?name_ar=${encodeURIComponent(
                            productData?.category?.name_ar || ""
                          )}&name_en=${encodeURIComponent(
                            productData?.category?.name || ""
                          )}&subCat=${
                            productData?.subCategoryId
                          }&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
                        }}
                        state={{
                          selectedSubcategory: productData?.subCategory,
                        }}
                      >
                        {" "}
                        <p>{translation.Globals.see_all}</p>
                      </Link>
                    ) : null}
                  </div>

                  <div className="crds_sec related_prdcts">
                    {relatedProducts?.length
                      ? relatedProducts?.slice(0, 7)?.map((item) => {
                          return (
                            <ProductCard
                              key={item?._id}
                              product_id={item?._id}
                              product_image={item?.images[0]}
                              product_feature={
                                language === "ar"
                                  ? item?.category?.name_ar
                                  : item?.category?.name
                              }
                              product_name={item?.name}
                              product_price={item?.price}
                              location={item?.address}
                              setFavItems={setFavItems}
                              booster_applied={item?.boosterApplied}
                              favItems={favItems}
                              item={item}
                              loading={loading}
                              lang={item?.lang}
                              routePath={`/product-detail/${item?._id}`}
                            />
                          );
                        })
                      : null}
                  </div>
                </section>
              ) : null}
            </>
          )}
        </div>
      </div>
      <DocumentViewer
        open={openImage}
        setOpen={setOpenImage}
        images={productData?.images}
      />
      <Modal
        // BackdropClick={false}

        open={openModal}
        onClose={() => setOpenModal(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.profile.delete_product || ""}
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{
                  color: "#252525",
                  border: "1px solid #7dbfdd",
                }}
                onClick={() => {
                  setOpenModal(false);
                  handleDeleteProduct(selectedId);
                }}
              >
                {translation.Globals.yes || ""}
              </Button>

              <Button
                sx={{
                  color: "#252525",
                  border: "1px solid #7dbfdd",
                }}
                onClick={() => setOpenModal(false)}
              >
                {translation.Globals.no || ""}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openOfferModal}
        onClose={() => setOpenOfferModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="make_an_offer">
            <div className="cross ">
              <CloseIcon
                onClick={() => {
                  setOpenOfferModal(false);
                  setOfferAmount(null);
                }}
              />
            </div>
            <h2
              style={{
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.product.make_offer}
            </h2>
            <div className="offers_showing">
              <div
                className={
                  offerAmount === Number(productData?.price) - 10
                    ? "active_price ofrs_dv"
                    : "ofrs_dv"
                }
                onClick={() => {
                  productData?.price === 0
                    ? setOfferAmount(Number(productData?.price))
                    : setOfferAmount(Number(productData?.price) - 10);
                }}
              >
                {translation.Globals.aed}{" "}
                {productData?.price ? productData?.price - 10 : "0"}
              </div>
              <div
                className={
                  offerAmount === Number(productData?.price)
                    ? "active_price ofrs_dv"
                    : "ofrs_dv"
                }
                onClick={() => {
                  setOfferAmount(Number(productData?.price));
                }}
              >
                {" "}
                {translation.Globals.aed} {productData?.price || "0"}
              </div>
              <div
                className={
                  offerAmount === Number(productData?.price) + 10
                    ? "active_price ofrs_dv"
                    : "ofrs_dv"
                }
                onClick={() => {
                  setOfferAmount(Number(productData?.price) + 10);
                }}
              >
                {translation.Globals.aed}{" "}
                {productData?.price ? productData?.price + 10 : "10"}
              </div>
            </div>
            <div className="make_ofr">
              <TextField
                className="ofr_inpt"
                id="standard-basic"
                placeholder={translation.product.amount}
                variant="standard"
                value={offerAmount}
                inputProps={{ maxLength: 8 }}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const regx = /^\d*\.?\d{0,2}$/;

                  if (regx.test(inputValue)) {
                    setOfferAmount(inputValue);
                  }
                }}
              />
            </div>

            <Button
              onClick={() => {
                handleMakeOffer(id as string);
              }}
            >
              {translation.product.send}
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openReport}
        onClose={() => setOpenReport(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenReport(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.view_profile.product_report || ""}
            </h2>
            <div className="logut_flx1">
              <TextField
                style={{ margin: 0 }}
                type="text"
                className="text_field text_area"
                hiddenLabel
                variant="outlined"
                placeholder={translation.view_profile.your_reason}
                multiline
                rows={4}
                fullWidth
                name="description"
                id="description"
                value={report}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    setReport(val.target.value);
                  }
                }}
              />
            </div>
            {error && !report ? (
              <h6 className="err_msg">
                {translation.validations.required_field}
              </h6>
            ) : (
              ""
            )}
            <div style={{ width: "50%", margin: "auto" }}>
              <ButtonComponent
                value={translation.view_profile.report}
                onClick={() => handleReportProduct(id)}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default ProductDetail;
