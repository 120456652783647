import React, { useEffect, useState } from "react";
import { END_POINTS, WORDPRESS_URL } from "../../helpers";
import {
  WORDPRESS_PASSWORD,
  WORDPRESS_USERNAME,
} from "../../helpers/constants/urls";
import Layout from "../../layout";
import {
  HotDeals,
  SeasonalBanner,
  SeasonalOffer1,
  SeasonalOffer2,
  TopOffers,
} from "../../features";
import "./SeasonalOffers.scss";

const SeasonalOffers = () => {
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState(null);
  const [offer1, setOffer1] = useState(null);
  const [offer2, setOffer2] = useState(null);

  const getOffers = async () => {
    const url = `${WORDPRESS_URL}${END_POINTS.seasonalOffers}/2`;

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${btoa(
            `${WORDPRESS_USERNAME}:${WORDPRESS_PASSWORD}`
          )}`,
        },
      });
      if (response?.ok) {
        setLoading(false);
        const responseData = await response.json();
        if (responseData?.status === 200) {
          setBannerData(responseData?.data?.banner_section);
          setOffer1(responseData?.data?.offer_1);
          setOffer2(responseData?.data?.offer_2);
        }
      } else {
        setOffer1(null);
        setOffer2(null);
        setBannerData(null);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    getOffers();
  }, []);

  return (
    <Layout>
      <SeasonalBanner bannerData={bannerData} />
      <TopOffers />
      <SeasonalOffer1 offer1={offer1} />
      <HotDeals />
      <SeasonalOffer2 offer2={offer2} />
    </Layout>
  );
};

export default SeasonalOffers;
