import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Button } from "../../components";
import useTranslation from "../../hooks/Translation";

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 2.5,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const TopManufacturers = ({ topManufacturers }: any) => {
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  return (
    <section className="prodcts_sc container">
      <div className="prdct_hdngs ">
        <h2>{topManufacturers?.heading || ""}</h2>
        {topManufacturers?.top_manufacture_data?.length > 1 ? (
          <Link to={`/top-manufacturers`}>
            {" "}
            <p>{translation.Globals.see_all}</p>
          </Link>
        ) : null}
      </div>
      <div className="manufacture_sc">
        {topManufacturers?.top_manufacture_data?.length ? (
          <Slider {...settings}>
            {topManufacturers?.top_manufacture_data?.map(
              (item: any, i: number) => {
                return (
                  <div className="manufacture_single" key={i}>
                    <figure>
                      <LazyLoadImage
                        src={item?.image}
                        alt="Manufacturer"
                        effect="blur"
                        width={450}
                        height={450}
                      />
                    </figure>
                    <div className="inner_content">
                      <h5>{item?.heading || ""}</h5>
                      <Button
                        value={item?.button_text || ""}
                        onClick={() => {
                          if (item?.url) {
                            window.open(
                              item?.url?.startsWith("http")
                                ? item?.url
                                : `https://${item?.url}`
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </Slider>
        ) : null}
      </div>
    </section>
  );
};

export default TopManufacturers;
