import { BlogCard } from "../../components";

const BlogsCategories = ({ blogs }: any) => {
  return (
    <>
      {blogs?.length
        ? blogs?.map((item: { heading: string; cat: number; data: any[] }) => {
            return (
              <section className="blog_cat container">
                <div className="blg_hd">
                  <h2>{item?.heading || "-"}</h2>
                  <a href={`/all-blogs/${item?.cat}`}>
                    <p>See All</p>
                  </a>
                </div>
                <div className="blog_data">
                  {item?.data?.length
                    ? item?.data?.slice(0, 3)?.map((val: any) => {
                        return (
                          <BlogCard
                            key={val?.blog_id}
                            image={val?.main_image || ""}
                            date={val?.date || ""}
                            author={val?.author || ""}
                            heading={val?.heading || ""}
                            blogId={val?.blog_id || ""}
                            cat={val?.cat || ""}
                            description={val?.description || ""}
                          />
                        );
                      })
                    : null}
                </div>
              </section>
            );
          })
        : null}
    </>
  );
};

export default BlogsCategories;
