import { useEffect, useState } from "react";
import { ProductCard, ProductCardSkeleton } from "../../components";
import { RecentProduct } from "../../types/General";
import { errorToast, getFromStorage, STORAGE_KEYS } from "../../helpers";
import { useGetHotDealsMutation } from "../../service/Home";
import useTranslation from "../../hooks/Translation";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";

const HotDeals = () => {
  const translation = useTranslation() as any;
  const language = getFromStorage(STORAGE_KEYS.language);
  const [loading, setLoading] = useState(false);
  const [hotDeals, setHotDeals] = useState<RecentProduct[]>([]);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [favItems, setFavItems] = useState<any[]>([]);
  const tempToken = useAppSelector(getTempToken);
  const token = getFromStorage(STORAGE_KEYS.token);

  const [getHotDeals] = useGetHotDealsMutation();

  const fetchHotDeals = async () => {
    setLoading(true);
    try {
      const response = await getHotDeals({
        page: page,
        categoryId: "",
        sort: "",
        minPrice: 0,
        maxPrice: 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setHotDeals(response?.data?.products);
        setCount(response?.data?.count);
      } else {
        setLoading(false);
        setHotDeals([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const checkFavProperties = () => {
    const fav_data = hotDeals
      ?.filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [hotDeals]);

  useEffect(() => {
    if (token || tempToken) {
      fetchHotDeals();
    }
  }, [tempToken]);

  return (
    <>
      {loading ? (
        <section className="prodcts_sc container">
          <div className="crds_sec">
            {Array.from({ length: 5 }, (_, index) => (
              <ProductCardSkeleton key={index} />
            ))}
          </div>
        </section>
      ) : (
        <>
          {hotDeals?.length ? (
            <section className="prodcts_sc whlt_sc container">
              <div className="prdct_hdngs ">
                <h2>{translation.Globals.hot_deals}</h2>
                {hotDeals?.length > 7 ? (
                  <Link to={`/all-products/${"hot-deals"}`}>
                    {" "}
                    <p>{translation.Globals.see_all}</p>
                  </Link>
                ) : null}
              </div>

              <div className="crds_sec related_prdcts">
                {hotDeals?.length
                  ? hotDeals?.slice(0, 7)?.map((item) => {
                      return (
                        <ProductCard
                          key={item?._id}
                          product_id={item?._id}
                          product_image={item?.images[0]}
                          product_feature={
                            language === "ar"
                              ? item?.category?.name_ar
                              : item?.category?.name
                          }
                          product_name={item?.name}
                          product_price={item?.price}
                          location={item?.address}
                          setFavItems={setFavItems}
                          booster_applied={item?.boosterApplied}
                          favItems={favItems}
                          item={item}
                          loading={loading}
                          lang={item?.lang}
                          routePath={`/product-detail/${item?._id}?cat_id=${
                            item?.category?._id
                          }&name_en=${encodeURIComponent(
                            item?.category?.name
                          )}`}
                        />
                      );
                    })
                  : null}
              </div>
            </section>
          ) : null}
        </>
      )}
    </>
  );
};

export default HotDeals;
