import { Route, Routes } from "react-router-dom";
import Pages from "./pages";
import ProtectedRoutes from "./utils/protectedRoutes";

const Routing = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pages.HomePage />} />
        <Route
          path="/choose-category"
          element={
            <ProtectedRoutes>
              <Pages.ChooseCategory />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/wishlist"
          element={
            <ProtectedRoutes>
              <Pages.Wishlist />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoutes>
              <Pages.Profile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/add-product/:id"
          element={
            <ProtectedRoutes>
              <Pages.AddProduct />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/add-product/:id/:prodId"
          element={
            <ProtectedRoutes>
              <Pages.AddProduct />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoutes>
              <Pages.Chat />
            </ProtectedRoutes>
          }
        />
        <Route path="/products/:id" element={<Pages.Filters />} />
        <Route
          path="/notifications"
          element={
            <ProtectedRoutes>
              <Pages.Notifications />
            </ProtectedRoutes>
          }
        />

        <Route path="/product-detail/:id" element={<Pages.ProductDetail />} />
        <Route path="/all-products/:name" element={<Pages.SeeAll />} />
        <Route path="/contact-us" element={<Pages.ContactUs />} />
        <Route path="/about-us" element={<Pages.AboutUs />} />
        <Route path="/faqs" element={<Pages.Faqs />} />
        <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
        <Route
          path="/terms-and-conditions"
          element={<Pages.TermsConditions />}
        />
        <Route path="/consumer-rights" element={<Pages.ConsumerRights />} />
        <Route
          path="/server-maintenance"
          element={<Pages.ServerMaintainance />}
        />
        <Route path="/official-stores" element={<Pages.OfficialStores />} />

        <Route
          path="/view-profile/:id"
          element={
            <ProtectedRoutes>
              <Pages.ViewProfile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/searched-products/:search"
          element={<Pages.SearchedProducts />}
        />
        <Route path="/blog" element={<Pages.Blogs />} />
        <Route path="/seasonal-offers" element={<Pages.SeasonalOffers />} />
        <Route path="/sell" element={<Pages.Sell />} />
        <Route path="/weekly-deals/:id" element={<Pages.WeeklyDeals />} />
        <Route path="/all-blogs/:id" element={<Pages.AllBlogs />} />
        <Route path="/blog-detail/:id" element={<Pages.BlogDetail />} />
        <Route path="/top-manufacturers" element={<Pages.TopManufacturers />} />
      </Routes>
    </div>
  );
};
export default Routing;
