import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "../contactUs/Cms.scss";
import { useLazyGetFaqQuery } from "../../service/Cms";
import { Faq } from "../../types/General";
import { Loader, STORAGE_KEYS, getFromStorage } from "../../helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useTranslation from "../../hooks/Translation";

const Faqs = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [faqData, setFaqData] = useState<Faq[]>([]);
  const [getFaq, { isLoading }] = useLazyGetFaqQuery();

  const fetchData = async () => {
    try {
      const response = await getFaq({}).unwrap();

      if (response?.statusCode === 200) {
        setFaqData(response?.data?.data || []);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const style = {
    borderRadius: "14px",
    boxShadow: "1px 1px 5px 4px #0000001A",
  };
  const border = {
    borderTop: "1px solid #DEDEDE",
  };
  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="common_policy ">
        <div className="container faq_dv">
          <h1> {translation.footer.faq}</h1>
          <ul className="faq_accoden">
            {faqData?.length ? (
              faqData?.map((item) => {
                return (
                  <li key={item?._id}>
                    <Accordion sx={style}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <p style={{ color: "#820000" }}>
                          {language === "ar"
                            ? item?.question_ar || ""
                            : item?.question || ""}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails sx={border}>
                        <Typography>
                          {language === "ar"
                            ? item?.answer_ar || ""
                            : item?.answer || ""}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>
                {translation.Globals.no_faq}
              </p>
            )}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Faqs;
