import React, { useEffect, useState } from "react";
import { useLazyGetFollowListQuery } from "../../service/Product";
import useAuth from "../../hooks/useAuth";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../helpers";
import { FollowList } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import { useNavigate } from "react-router";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type props = {
  scrollPosition: any;
};
const TotalFollowers = ({ scrollPosition }: props) => {
  const navigate = useNavigate();
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const user = useAuth();
  const [followers, setFollowers] = useState<FollowList[]>([]);
  const [getFollowers, { isLoading }] = useLazyGetFollowListQuery();

  const fetchData = async () => {
    try {
      const response = await getFollowers({
        userId: user?._id,
        type: 1,
      }).unwrap();
      if (response?.statusCode === 200) {
        setFollowers(response?.data?.followers || []);
      } else {
        setFollowers([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="main">
      <Loader isLoad={isLoading} />
      <h2>{translation.view_profile.total_followers}</h2>
      <ul className="followers_lst">
        {followers?.length ? (
          followers?.map((item) => {
            return (
              <li
                className="lstt"
                key={item?._id}
                onClick={() =>
                  navigate(`/view-profile/${item?.followedBy?._id}`)
                }
              >
                <figure
                  className="delete_dv"
                  style={{
                    marginRight: language === "ar" ? 0 : "16px",
                    marginLeft: language === "ar" ? "16px" : "0px",
                  }}
                >
                  <LazyLoadImage
                    alt="user_placeholder"
                    src={
                      item?.followedBy?.image ||
                      "/static/images/user_placeholder.png"
                    }
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                    scrollPosition={scrollPosition}
                  />
                </figure>
                <h4>{item?.followedBy?.fullName || ""}</h4>
              </li>
            );
          })
        ) : (
          <div className="no_followers_found">
            <p>{translation.profile.no_followers}</p>
          </div>
        )}
      </ul>
    </div>
  );
};

export default trackWindowScroll(TotalFollowers);
