import { useMemo } from "react";
import { useAppSelector } from "./store";

import { categories } from "../reducers/generalSlice";

function useCategory() {
  const categoryData = useAppSelector(categories);
  return useMemo(() => categoryData, [categoryData]);
}

export default useCategory;
