import React, { useEffect, useState } from "react";
import { ProductCard } from "../../components";
import { RecentProduct } from "../../types/General";
import { Link, useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//recent

type props = {
  recentProductsData: RecentProduct[];
};

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 7,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
  // autoplay: true,
  // speed: 2000,
  // autoplaySpeed: 5000,
};

const PopularProducts = ({ recentProductsData }: props) => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const [favItems, setFavItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const fav_data = recentProductsData
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [recentProductsData]);

  return (
    <section className="prodcts_sc container rcmd_pr">
      <div className="prdct_hdngs ">
        <h2>{translation.Globals.recent_products}</h2>
        {recentProductsData?.length > 5 ? (
          <Link to={`/all-products/${"recent"}`}>
            <p>{translation.Globals.see_all}</p>
          </Link>
        ) : null}
      </div>

      {recentProductsData?.length ? (
        <Slider {...settings}>
          {recentProductsData?.map((item) => {
            return (
              <ProductCard
                key={item?._id}
                product_id={item?._id}
                product_image={item?.images[0]}
                product_feature={
                  language === "ar"
                    ? item?.category?.name_ar
                    : item?.category?.name
                }
                product_name={item?.name}
                product_price={item?.price}
                location={item?.address}
                booster_applied={item?.boosterApplied}
                setFavItems={setFavItems}
                favItems={favItems}
                item={item}
                lang={item?.lang}
                routePath={`/product-detail/${item?._id}?cat_id=${
                  item?.category?._id
                }&name_en=${encodeURIComponent(item?.category?.name)}`}
              />
            );
          })}
        </Slider>
      ) : (
        <div className="noData">
          <figure>
            <LazyLoadImage
              src={"/static/images/empty.png"}
              alt="empty"
              effect="blur"
              width={"100%"}
            />
          </figure>
          <h3>{translation.Globals.no_product}</h3>
        </div>
      )}
    </section>
  );
};

export default PopularProducts;
