import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ProductBannerData } from "../../types/General";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Skeleton } from "@mui/material";

type props = {
  productBannerData: ProductBannerData[];
  bannerLoading: boolean;
};

const Banners = ({ bannerLoading, productBannerData }: props) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const mainBanners = productBannerData?.filter((item) => item?.type === 1);

  useEffect(() => {
    if (!bannerLoading && mainBanners.length > 0) {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = mainBanners[0]?.webImage || "/static/images/facebook.png";
      document.head.appendChild(link);
    }
  }, [bannerLoading, mainBanners]);

  return (
    <section className="container home_banner_sc">
      {!bannerLoading ? (
        <Slider {...settings}>
          {mainBanners?.map((item) => {
            return item?.link?.length ? (
              <a
                key={item?._id}
                href={
                  item?.link?.startsWith("http")
                    ? item?.link
                    : `https://${item?.link}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="bnr">
                  <LazyLoadImage
                    className="bnr_img_style"
                    src={item?.webImage || "/static/images/facebook.png"}
                    alt="banner"
                    effect="blur"
                    width={1170}
                    height={240}
                  />
                </div>
              </a>
            ) : (
              <div className="bnr">
                <LazyLoadImage
                  className="bnr_img_style"
                  src={item?.webImage || "/static/images/facebook.png"}
                  alt="banner"
                  effect="blur"
                  width={1170}
                  height={240}
                />
              </div>
            );
          })}
        </Slider>
      ) : (
        <Skeleton variant="rounded" width={"100%"} height={240} />
      )}
    </section>
  );
};

export default Banners;
