import { Skeleton } from "@mui/material";

const BlogCardSkeleton = () => {
  return (
    <div className="blog_crd">
      <figure>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={"100%"}
        />
      </figure>
      <div className="blg_dt">
        <p>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"70px"}
            animation="wave"
          />
        </p>

        <h5>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={"90px"}
            animation="wave"
          />
        </h5>
      </div>
      <h3>
        {" "}
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={"110px"}
          animation="wave"
        />
      </h3>

      <p>
        {" "}
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation="wave" />
      </p>
    </div>
  );
};

export default BlogCardSkeleton;
