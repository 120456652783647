import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Layout from "../../layout";
import { Pagination, ProductCard, ProductCardSkeleton } from "../../components";
import { useGetAllfavPropertyMutation } from "../../service/Product";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";
import { RecommendedProductsData } from "../../types/General";
import useTranslation from "../../hooks/Translation";

const Wishlist = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [loading, setLoading] = useState(true);
  const [favProperty] = useGetAllfavPropertyMutation();
  const [wishlistData, setWishlistData] = useState<RecommendedProductsData[]>(
    []
  );
  const [page, setPage] = useState(1);

  const [wishlistCount, setWishlistCount] = useState<number>(0);

  let totalPages = Math.ceil(wishlistCount / 85);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getFavProperty = async () => {
    setLoading(true);
    try {
      const response = await favProperty({
        type: "1",
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setWishlistData(response?.data?.wishList || []);
        setWishlistCount(response?.data?.count);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const [favItems, setFavItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const fav_data = wishlistData
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [wishlistData]);

  useEffect(() => {
    getFavProperty();
  }, []);

  return (
    <Layout>
      <div className="wrapCls">
        <div className="prodcts_sc container whlt_sc">
          <div className="crds_sec">
            {loading ? (
              Array.from({ length: 5 }, (_, index) => (
                <ProductCardSkeleton key={index} />
              ))
            ) : favItems?.length ? (
              favItems?.map((item) => {
                return (
                  <ProductCard
                    wishlist={item?.isFav}
                    key={item?._id}
                    product_id={item?._id}
                    product_image={item?.images[0]}
                    product_feature={
                      language === "ar"
                        ? item?.category?.name_ar
                        : item?.category?.name
                    }
                    product_name={item?.name}
                    product_price={item?.price}
                    location={item?.address}
                    booster_applied={item?.boosterApplied}
                    setFavItems={setFavItems}
                    favItems={favItems}
                    item={item}
                    lang={item?.lang}
                    routePath={`/product-detail/${
                      item?._id
                    }?cat_id=${""}&name_en=${encodeURIComponent(
                      "wishlist" || ""
                    )}`}
                  />
                );
              })
            ) : (
              <div className="noData">
                <figure>
                  <LazyLoadImage
                    src="/static/images/empty.png"
                    alt="empty"
                    effect="blur"
                    width={"100%"}
                    height={"100%"}
                  />
                </figure>
                <h3>{translation.Globals.no_product}</h3>
              </div>
            )}
          </div>
          <div className="vw_prfl_pgntn">
            <Pagination
              module={wishlistData}
              page={page || 1}
              onPageChange={onPageChange}
              totalPages={totalPages}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wishlist;
