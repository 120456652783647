import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { FormControl } from "@mui/material";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import "../auth/Auth.scss";
import OTPInput from "react-otp-input";

import { toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch } from "../../hooks/store";
import CloseIcon from "@mui/icons-material/Close";
import {
  useLazyGetProfileQuery,
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../service/Auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
  successToast,
} from "../../helpers";
import useTranslation from "../../hooks/Translation";

type Props = {
  closeModal: () => void;
  phone: boolean;
  setPhone: Dispatch<SetStateAction<boolean>>;
  signUp: boolean;
  emailValue?: string;
  phoneNumber?: string;
  dialCode?: string;
};

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
  paddingBottom: 20,
};

const Verification = ({
  closeModal,
  phone,
  signUp,
  emailValue,
  phoneNumber,
  dialCode,
  setPhone,
}: Props) => {
  const translation = useTranslation() as any;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);
  const [postVerifyOtp, { isLoading }] = usePostVerifyOtpMutation();
  const [getProfile] = useLazyGetProfileQuery();
  const [postForgotPassword, GetForgotPasswordData] =
    usePostForgotPasswordMutation();

  // const firstInputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   if (firstInputRef.current) {
  //     firstInputRef.current.focus();
  //   }
  // }, []);

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleResendOtp = async () => {
    let data = {
      ...(!phone
        ? { key: emailValue }
        : {
            key: phoneNumber,
            countryCode: dialCode,
          }),
      resend: true,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await postForgotPassword(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");

      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      const data = {
        ...(!phone
          ? { key: emailValue, code: otp }
          : {
              key: phoneNumber,
              countryCode: dialCode,
              code: otp,
            }),
        deviceType: "WEB",
        deviceToken: fcmtoken || "1234",
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postVerifyOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (phone && !signUp) {
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token)
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data || "")
            );
            dispatch(
              setCredentials({
                token: response?.data?.token || "",
                user: response?.data || "",
              })
            );
            dispatch(
              temporaryToken({
                tempToken: null,
              })
            );
            closeModal();
            if (response?.data?.isProfileComplete) {
              navigate("/");
            } else {
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "profileSetup",
                })
              );
            }

            getUserDetails();
          } else if (signUp) {
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token)
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data || "")
            );

            dispatch(
              setCredentials({
                token: response?.data?.token || "",
                user: response?.data || "",
              })
            );
            dispatch(
              temporaryToken({
                tempToken: null,
              })
            );
            closeModal();
            dispatch(
              toggleAuthModal({
                isAuthModalVisible: true,
                ModalType: "profileSetup",
              })
            );
          } else {
            setToStorage(
              STORAGE_KEYS.tempToken,
              JSON.stringify(response?.data?.token)
            );
            dispatch(
              temporaryToken({
                tempToken: response?.data?.token || null,
              })
            );

            dispatch(
              toggleAuthModal({ isAuthModalVisible: true, ModalType: "reset" })
            );
          }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <div>
      <div className="AuthWrap">
        <Loader isLoad={isLoading || GetForgotPasswordData?.isLoading} />
        <div className="cross">
          <CloseIcon
            onClick={() => {
              setPhone(true);
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              );
            }}
          />
        </div>
        <h2>{translation.Auth.otp_verification.verification}</h2>

        {phone ? (
          <p>
            {translation.Auth.otp_verification.phone_code}{" "}
            <span>
              {dialCode && phoneNumber ? dialCode + " " + phoneNumber : ""}
            </span>
          </p>
        ) : (
          <p>
            {translation.Auth.otp_verification.email_code}{" "}
            <span>{emailValue || ""}</span>
          </p>
        )}

        <FormControl className="opt_fields" sx={{ width: "100%" }}>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputStyle={otpStyle}
            inputType="tel"
            shouldAutoFocus
          />
          <br />
          {error && otp.length !== 4 ? (
            <h6 className="err_msg" style={{ textAlign: "center" }}>
              {translation.validations.required_field}
            </h6>
          ) : (
            ""
          )}
        </FormControl>

        <Button value={translation.Globals.next || ""} onClick={handleSubmit} />

        {countDown === 0 ? (
          <div className="resend">
            <h4 onClick={handleResendOtp}>
              {translation.Auth.otp_verification.resend}
            </h4>
          </div>
        ) : (
          <div className="continue" style={{ marginBottom: "10px" }}>
            <h5>
              {" "}
              {translation.Auth.otp_verification.code_expire} 00 :{" "}
              {countDown < 10 ? `0${countDown}` : countDown}
            </h5>
          </div>
        )}
      </div>
    </div>
  );
};
export default Verification;
