import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage, setToStorage } from "../../helpers";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import { useLazyGetProfileQuery } from "../../service/Auth";
import useTranslation from "../../hooks/Translation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LogoHeader = () => {
  const translation = useTranslation() as any;
  const getLang = getFromStorage(STORAGE_KEYS.language);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getProfile] = useLazyGetProfileQuery();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.userData);

    if (token) {
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: JSON.parse(`${userData}`) || null,
        })
      );
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element
    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      const isArabic = language === "ar";
      // Remove the opposite class before adding the new one
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };
  useEffect(() => {
    change();
  }, [getLang]);

  useEffect(() => {
    if (getLang === null) {
      setToStorage(STORAGE_KEYS.language, "en");
    }
  }, []);

  return (
    <header className="logo-header">
      <div className="logo_dv">
        <figure className="logo_img" onClick={() => navigate("/")}>
          <LazyLoadImage
            src={"/static/images/krafo_logo.png"}
            alt="krafo_logo"
            effect="blur"
            width={"100%"}
            height={"100%"}
          />
        </figure>
        <h6>{translation.Globals.wholesale || ""}</h6>
      </div>
    </header>
  );
};

export default LogoHeader;
