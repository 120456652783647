// @ts-nocheck
import { Link } from "react-router-dom";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FeaturedProductsData } from "../../types/General";
import useTranslation from "../../hooks/Translation";

import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import QuickView from "../../components/QuickView";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { GoogleTranslateScript } from "../../utils/GoogleTranslateScript";

type props = {
  featuredProducts: FeaturedProductsData[];
};

const settin = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToScroll: 1,
  rows: 2,
  slidesPerRow: 3,
  responsive: [
    {
      breakpoint: 993,
      settings: {
        rows: 1,
        slidesPerRow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        rows: 1,
        slidesPerRow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesPerRow: 1,
      },
    },
  ],
};

const Featureproduct = ({ featuredProducts }: props) => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<FeaturedProductsData>();
  const [selectedImage, setSelectedImage] = useState("");

  const [translatedProductName, setTranslatedProductName] = useState("");

  const targetLanguage = language === "ar" ? "ar" : "en";

  // const handleTranslate = async () => {
  //   const productNameArray = featuredProducts
  //     ?.map((item) => item?.name)
  //     .filter(Boolean);

  //   if (productNameArray?.length > 0) {
  //     const translatedTexts = await Promise.all(
  //       productNameArray.map(async (productName) => {
  //         const translatedText = await GoogleTranslateScript(
  //           productName,
  //           targetLanguage
  //         );
  //         return translatedText;
  //       })
  //     );
  //     setTranslatedProductName(translatedTexts);
  //   }
  // };
  // useEffect(() => {
  //   handleTranslate();
  // }, [language, featuredProducts]);

  const handleOpenPopup = (e: any, item: FeaturedProductsData) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedImage(item?.images[0]);
    setSelectedItem(item);
    setOpen(true);
  };

  return (
    <section className="prodcts_sc container ofclstr featureprdt">
      <div className="prdct_hdngs ">
        <h2>{translation.Globals.featured_products}</h2>
        {featuredProducts?.length > 5 ? (
          <Link to={`/all-products/${"featured"}`}>
            <p>{translation.Globals.see_all}</p>
          </Link>
        ) : null}
      </div>
      <div className="">
        {featuredProducts?.length ? (
          <Slider {...settin}>
            {featuredProducts?.map((item, index) => {
              return (
                <li key={item?._id}>
                  <Link
                    to={`/product-detail/${item?._id}`}
                    className="prdct_crd prdct_str"
                  >
                    <figure className="prdct_img">
                      <figure
                        className="zoom_icon"
                        onClick={(e) => handleOpenPopup(e, item)}
                      >
                        <ZoomInIcon />
                      </figure>
                      <LazyLoadImage
                        alt="prdct_img"
                        src={item?.images[0]}
                        effect="blur"
                        height={100}
                        width={100}
                      />
                    </figure>
                    <div className="prdct_dtl">
                      <h3>
                        {" "}
                        {/* {language === "en" ? (
                          <>
                            {item?.lang === 1
                              ? item?.name
                              : translatedProductName[index] || ""}
                          </>
                        ) : (
                          <>
                            {item?.lang === 2
                              ? item?.name
                              : translatedProductName[index] || ""}
                          </>
                        )}{" "} */}
                        {item?.name || "-"}
                      </h3>
                      {/* <Rating name="no-value" value={null} readOnly /> */}
                      {item?.price === 0 || !item?.price ? (
                        <h4 className="mt_top_10">
                          {translation.Globals.askPrice || ""}
                        </h4>
                      ) : (
                        <h4 className="mt_top_10">
                          {translation.Globals.aed}{" "}
                          {item?.price.toLocaleString("en-IN") || 0}
                        </h4>
                      )}
                    </div>
                  </Link>
                </li>
              );
            })}
          </Slider>
        ) : (
          <div className="noData">
            <figure>
              <LazyLoadImage
                src={"/static/images/empty.png"}
                alt="empty"
                effect="blur"
                width={"100%"}
              />
            </figure>
            <h3>{translation.Globals.no_product}</h3>
          </div>
        )}
      </div>
      <QuickView
        open={open}
        setOpen={setOpen}
        item={selectedItem}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
    </section>
  );
};

export default Featureproduct;
