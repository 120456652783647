import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./Notifications.scss";
import {
  useLazyClearNotificationQuery,
  useLazyGetNotificationQuery,
} from "../../service/Cms";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  successToast,
} from "../../helpers";
import { NotificationsResponse } from "../../types/General";
import { Pagination } from "../../components";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import "moment/locale/ar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Notifications = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  moment.locale(language === "ar" ? "ar" : "en");
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const [notification, { isLoading }] = useLazyGetNotificationQuery();
  const [clearNotification] = useLazyClearNotificationQuery();
  const [recentNotificationData, setRecentNotificationData] = useState<
    NotificationsResponse[]
  >([]);
  const [olderNotificationData, setOlderNotificationData] = useState<
    NotificationsResponse[]
  >([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getNotification = async () => {
    try {
      const response = await notification({
        page: page,
      }).unwrap();

      if (response?.statusCode === 200) {
        setRecentNotificationData(response?.data?.recentNotifications || []);
        setOlderNotificationData(response?.data?.olderNotifications || []);
        setTotalCount(response?.data?.oldNotificationCount);
      } else {
        setRecentNotificationData([]);
        setOlderNotificationData([]);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  const handleClearNotifications = async () => {
    try {
      const response = await clearNotification({}).unwrap();

      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.notifications_clear);
        getNotification();
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };
  useEffect(() => {
    getNotification();
  }, [page]);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls container notification_pg">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <p>{translation.Globals.recent}</p>
          {olderNotificationData?.length || recentNotificationData?.length ? (
            <h4 className="clr_ntfctn_btn" onClick={handleClearNotifications}>
              {translation.Globals.clear_notifications}
            </h4>
          ) : null}
        </div>

        {recentNotificationData?.length ? (
          recentNotificationData?.map((item) => {
            return (
              <div
                className="rcnt_notification"
                key={item?._id}
                onClick={() => {
                  if (item?.pushType === 7 || item?.pushType === 11) {
                    navigate("/chat", {
                      state: {
                        type: "notification",
                        roomId: item?.roomId,
                        senderId: item?.senderId,
                        senderImage: item?.senderImage,
                        senderName: item?.senderName,
                        productName: item?.productName,
                        productId: item?.productId,
                        productLang: item?.productLang,
                      },
                    });
                  } else if (item?.pushType === 12) {
                    navigate(`/view-profile/${item?.senderId}`);
                  } else if (item?.pushType === 13) {
                    navigate("/wishlist");
                  } else if (item?.pushType === 16) {
                    navigate("/profile", { state: "seller_details" });
                  }
                }}
              >
                <div className="ntf_lft">
                  <figure
                    style={{
                      marginRight: language === "ar" ? "0" : "10px",
                      marginLeft: language === "ar" ? "10px" : "0px",
                    }}
                  >
                    <LazyLoadImage
                      src={
                        item?.senderImage || "/static/images/notification1.png"
                      }
                      alt="notification1"
                      effect="blur"
                      width={"100%"}
                      height={"100%"}
                    />
                  </figure>
                  <div>
                    <h6 style={{ color: "#7dbfdd", paddingBottom: "4px" }}>
                      {language === "en" || item?.pushType === 10
                        ? item?.title || ""
                        : item?.title_ar || ""}
                    </h6>
                    <h6>
                      {language === "en" || item?.pushType === 10
                        ? item?.message || ""
                        : item?.message_ar || ""}
                    </h6>
                  </div>
                </div>
                <p className="ntf_tm">
                  {moment(item?.createdAt)
                    .locale(language === "ar" ? "ar" : "en")
                    .fromNow()}
                </p>
              </div>
            );
          })
        ) : (
          <div
            style={{ height: "100%", textAlign: "center", padding: "50px 0" }}
          >
            {translation.Globals.no_recent_notifications}
          </div>
        )}

        <p> {translation.Globals.older_notifications}</p>

        {olderNotificationData?.length ? (
          olderNotificationData?.map((item) => {
            return (
              <div
                className="rcnt_notification old_notification"
                key={item?._id}
                onClick={() => {
                  if (item?.pushType === 7 || item?.pushType === 11) {
                    navigate("/chat", {
                      state: {
                        type: "notification",
                        roomId: item?.roomId,
                        senderId: item?.senderId,
                        senderImage: item?.senderImage,
                        senderName: item?.senderName,
                        productName: item?.productName,
                        productId: item?.productId,
                        productLang: item?.productLang,
                      },
                    });
                  } else if (item?.pushType === 12) {
                    navigate(`/view-profile/${item?.senderId}`);
                  } else if (item?.pushType === 13) {
                    navigate("/wishlist");
                  } else if (item?.pushType === 16) {
                    navigate("/profile", { state: "seller_details" });
                  }
                }}
              >
                <div className="ntf_lft">
                  <figure
                    style={{
                      marginRight: language === "ar" ? "0" : "10px",
                      marginLeft: language === "ar" ? "10px" : "0px",
                    }}
                  >
                    <LazyLoadImage
                      src={
                        item?.senderImage || "/static/images/notification1.png"
                      }
                      alt="notification1"
                      effect="blur"
                      width={"100%"}
                      height={"100%"}
                    />
                  </figure>
                  <div>
                    <h6 style={{ color: "#820000", paddingBottom: "4px" }}>
                      {language === "en" || item?.pushType === 10
                        ? item?.title || ""
                        : item?.title_ar || ""}
                    </h6>
                    <h6 style={{ color: "#1A1824" }}>
                      {language === "en" || item?.pushType === 10
                        ? item?.message || ""
                        : item?.message_ar || ""}
                    </h6>
                  </div>
                </div>
                <p className="ntf_tm2">
                  {" "}
                  {moment(item?.createdAt)
                    .locale(language === "ar" ? "ar" : "en")
                    .fromNow()}
                </p>
              </div>
            );
          })
        ) : (
          <div
            style={{ height: "100%", textAlign: "center", padding: "50px 0" }}
          >
            {translation.Globals.no_older_notifications}
          </div>
        )}
        {olderNotificationData?.length ? (
          <Pagination
            module={olderNotificationData}
            page={page || 1}
            onPageChange={onPageChange}
            totalPages={totalPages}
            setPage={setPage}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default Notifications;
