import { END_POINTS } from "../helpers";
import {
  ChatHistoryResponse,
  ChatResponse,
  CommonBody,
  DashboardData,
  FollowList,
  FollowingList,
  ProductDetailData,
  ProfileData,
  RecentProduct,
  RecommendedProductsData,
  RelatedProducts,
  boosterPlans,
  globalSearch,
} from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type WishlistRes = {
  count: number;
  wishList: RecommendedProductsData[];
};

type MyProductRes = {
  products: globalSearch[];
  count: number;
  pages: number;
};
type ChatResponseType = {
  chatCount: number;
  chat: any;
};
type FollowListResponse = {
  count: number;
  followers: FollowList[];
};
type FollowResponse = {
  count: number;
  following: FollowingList[];
};
type MakeOfferResponse = {
  amount: number;
  chatRoomId: string;
  message: string;
  product: string;
};
type ProductSearchData = {
  count: number;
  maxPrice: number;
  pages: number;
  productSearch: RecommendedProductsData[];
};
type RelatedProductsData = {
  count: number;
  maxPrice: number;
  pages: number;
  relatedProducts: RecentProduct[];
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductById: builder.query<
      CommonResponseType & { data: ProductDetailData },
      { product_id: string | undefined; searchHistory: boolean }
    >({
      query: ({ product_id, searchHistory }) => ({
        url: `${END_POINTS.getProduct}/${product_id}?searchHistory=${searchHistory}`,
        method: "GET",
      }),
    }),
    getRelatedProducts: builder.query<
      CommonResponseType & { data: RelatedProductsData },
      { subCategoryId: string | undefined; productId: string | undefined }
    >({
      query: ({ subCategoryId, productId }) => ({
        url: `${END_POINTS.relatedProducts}?productId=${productId}&subCategoryId=${subCategoryId}`,
        method: "GET",
      }),
    }),
    getProfileById: builder.query<
      CommonResponseType & { data: ProfileData },
      { user_id: string; category_id: string; page: number }
    >({
      query: ({ user_id, category_id, page }) => ({
        url: `${
          END_POINTS.getProfileById
        }/${user_id}?categoryId=${category_id}&page=${page}&size=${68}`,
        method: "GET",
      }),
    }),
    changeFavStatus: builder.mutation<
      CommonResponseType & { data: RecommendedProductsData },
      CommonBody
    >({
      query: (body) => ({
        url: `${END_POINTS.addWishlist}`,
        method: "POST",
        body,
      }),
    }),
    postFollowUnfollow: builder.mutation<
      CommonResponseType & { data: any },
      { user_id: string }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.follow}/${user_id}`,
        method: "POST",
      }),
    }),
    getFollowList: builder.query<
      CommonResponseType & { data: FollowListResponse },
      { userId: string | undefined; type: number }
    >({
      query: ({ userId, type }) => ({
        url: `${END_POINTS.followList}/${userId}?type=${type}`,
        method: "GET",
      }),
    }),
    getFollowingList: builder.query<
      CommonResponseType & { data: FollowResponse },
      { userId: string | undefined; type: number }
    >({
      query: ({ userId, type }) => ({
        url: `${END_POINTS.followList}/${userId}?type=${type}`,
        method: "GET",
      }),
    }),
    getAllfavProperty: builder.mutation<
      CommonResponseType & { data: WishlistRes },
      { type: string; page: number }
    >({
      query: ({ type, page }) => ({
        url: `${END_POINTS.getWishlist}?type=${type}&page=${page}&size=${85}`,
        method: "GET",
      }),
    }),
    addProduct: builder.mutation<
      CommonResponseType & { data: WishlistRes },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.addProduct,
        method: "POST",
        body,
      }),
    }),
    getMyProduct: builder.query<
      CommonResponseType & { data: MyProductRes },
      { type: number; categoryId: string; page: number }
    >({
      query: ({ type, categoryId, page }) => ({
        url: `${
          END_POINTS.getMyProduct
        }?type=${type}&categoryId=${categoryId}&page=${page}&size=${4}`,
        method: "GET",
      }),
    }),
    deletePropertyById: builder.mutation<
      CommonResponseType & { data: MyProductRes },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.deleteProduct}/${id}`,
        method: "DELETE",
      }),
    }),
    getMyProductById: builder.query<
      CommonResponseType & { data: globalSearch },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getProduct}/${id}`,
        method: "GET",
      }),
    }),
    updateProduct: builder.mutation<
      CommonResponseType & { data: globalSearch },
      { id: string; encryptedBody: CommonBody }
    >({
      query: ({ encryptedBody, id }) => ({
        url: `${END_POINTS.updateProduct}/${id}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    getBooster: builder.query<
      CommonResponseType & { data: { boosterPlans: boosterPlans[] } },
      {}
    >({
      query: () => ({
        url: END_POINTS.getBoosterPlans,
        method: "GET",
      }),
    }),
    getMyBooster: builder.query<
      CommonResponseType & { data: boosterPlans },
      {}
    >({
      query: () => ({
        url: END_POINTS.getMyBoosterPlan,
        method: "GET",
      }),
    }),
    addBooster: builder.mutation<
      CommonResponseType & { data: WishlistRes },
      { id: string; encryptedBody: CommonBody }
    >({
      query: ({ id, encryptedBody }) => ({
        url: `${END_POINTS.buyBooster}/${id}`,
        method: "POST",
        body: encryptedBody,
      }),
    }),
    postMakeOffer: builder.mutation<
      CommonResponseType & { data: MakeOfferResponse },
      { productId: string; encryptedBody: CommonBody }
    >({
      query: ({ encryptedBody, productId }) => ({
        url: `${END_POINTS.makeAnOffer}/${productId}`,
        method: "POST",
        body: encryptedBody,
      }),
    }),
    getChatList: builder.query<
      CommonResponseType & { data: ChatResponseType },
      { roomId: string; filter: number }
    >({
      query: ({ roomId, filter }) => ({
        url: `${END_POINTS.chatList}?roomId=${roomId}&filter=${filter}`,
        method: "GET",
      }),
    }),
    postClearChat: builder.mutation<
      CommonResponseType & { data: ChatResponseType },
      { roomId: string }
    >({
      query: ({ roomId }) => ({
        url: `${END_POINTS.clearChat}?roomId=${roomId}`,
        method: "POST",
      }),
    }),
    postAcceptRejectOffer: builder.mutation<
      CommonResponseType & { data: any },
      { product_id: string | undefined; body: CommonBody }
    >({
      query: ({ product_id, body }) => ({
        url: `${END_POINTS.offerResponse}/${product_id}`,
        method: "POST",
        body,
      }),
    }),
    postReportProduct: builder.mutation<
      CommonResponseType & { data: any },
      { product_id: string | undefined; encryptedBody: CommonBody }
    >({
      query: ({ product_id, encryptedBody }) => ({
        url: `${END_POINTS.reportProduct}/${product_id}`,
        method: "POST",
        body: encryptedBody,
      }),
    }),
    getSearchOnNextPage: builder.query<
      CommonResponseType & { data: ProductSearchData },
      { page: number; size: number; search: string | undefined }
    >({
      query: ({ page, size, search }) => ({
        url: `${END_POINTS.searchOnNextPage}?page=${page}&size=${size}&search=${search}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetProductByIdQuery,
  useLazyGetRelatedProductsQuery,
  useLazyGetProfileByIdQuery,
  usePostFollowUnfollowMutation,
  useChangeFavStatusMutation,
  useGetAllfavPropertyMutation,
  useAddProductMutation,
  useLazyGetMyProductQuery,
  useDeletePropertyByIdMutation,
  useLazyGetMyProductByIdQuery,
  useUpdateProductMutation,
  useLazyGetBoosterQuery,
  useAddBoosterMutation,
  usePostMakeOfferMutation,
  useLazyGetMyBoosterQuery,
  useLazyGetChatListQuery,
  usePostClearChatMutation,
  usePostAcceptRejectOfferMutation,
  useLazyGetFollowListQuery,
  useLazyGetFollowingListQuery,
  usePostReportProductMutation,
  useLazyGetSearchOnNextPageQuery,
} = authApi;
