import React from "react";

const BlogBanner = ({ bannerData }: any) => {
  return (
    <section className="home_banner_sc">
      <div
        className="blog_bnr"
        style={{
          backgroundImage: `url(${bannerData?.image})`,
        }}
      >
        <div className="container">
          <h1 className="bnr_cntnt">{bannerData?.heading || ""}</h1>
        </div>
      </div>
    </section>
  );
};

export default BlogBanner;
