import { END_POINTS } from "../helpers";
import {
  CommonBody,
  DashboardData,
  FeaturedProductsData,
  ProductSearchData,
  RecentProduct,
  RecommendedProductsData,
  globalSearch,
} from "../types/General";
import { Category } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type CategoriesData = {
  count: 9;
  pages: 1;
  category: Category[];
};

type RecentsProductsData = {
  count: number;
  maxPrice: number;

  pages: number;
  products: RecommendedProductsData[];
};

type SearchHistoryResponse = {
  productSearch: ProductSearchData[];
};
type FeaturedProductsType = {
  count: number;
  maxPrice: number;
  pages: number;
  products: FeaturedProductsData[];
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponseType & { data: DashboardData },
      {}
    >({
      query: () => ({
        url: END_POINTS.dashboard,
        method: "GET",
      }),
    }),
    getRecentProducts: builder.mutation<
      CommonResponseType & { data: RecentsProductsData },
      {}
    >({
      query: () => ({
        url: END_POINTS.recentProducts,
        method: "POST",
      }),
    }),
    dashboardRecentProducts: builder.query<
      CommonResponseType & { data: RecentsProductsData },
      {}
    >({
      query: () => ({
        url: END_POINTS.recentProduct,
        method: "GET",
      }),
    }),
    dashboardFeaturedProducts: builder.query<
      CommonResponseType & { data: FeaturedProductsData },
      {}
    >({
      query: () => ({
        url: END_POINTS.featuredProd,
        method: "GET",
      }),
    }),
    dashboardRecommendedProducts: builder.query<
      CommonResponseType & { data: RecommendedProductsData },
      {}
    >({
      query: () => ({
        url: END_POINTS.recommended,
        method: "GET",
      }),
    }),
    dashboardBanner: builder.query<
      CommonResponseType & { data: RecommendedProductsData },
      {}
    >({
      query: () => ({
        url: END_POINTS.banner,
        method: "GET",
      }),
    }),
    guestLogin: builder.mutation<
      CommonResponseType & { data: User },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.guestLogin,
        method: "POST",
        body,
      }),
    }),
    globalSearch: builder.query<
      CommonResponseType & {
        data: { ItemSearch: { productSearch: globalSearch[] } };
      },
      { search: string }
    >({
      query: ({ search }) => ({
        url: `${END_POINTS.globalSearch}?search=${search}`,
        method: "GET",
      }),
    }),
    getCategories: builder.query<
      CommonResponseType & { data: CategoriesData },
      {}
    >({
      query: ({}) => ({
        url: `${END_POINTS.category}?page=${1}&size=${20}`,
        method: "GET",
      }),
    }),
    postSaveSearchHistory: builder.mutation<
      CommonResponseType & { data: CategoriesData },
      CommonBody
    >({
      query: (body) => ({
        url: `${END_POINTS.saveSearchHistory}`,
        method: "POST",
        body,
      }),
    }),
    getSearchHistory: builder.query<
      CommonResponseType & { data: SearchHistoryResponse },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.searchHistory}`,
        method: "GET",
      }),
    }),
    postClearSearch: builder.mutation<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.clearSearch}`,
        method: "POST",
      }),
    }),
    getFeaturedProducts: builder.mutation<
      CommonResponseType & { data: FeaturedProductsType },
      {
        body: CommonBody;
        page: number;
        categoryId: string | undefined;
        sort: string;
        minPrice: number;
        maxPrice: number;
        size?: number;
      }
    >({
      query: ({ size, body, page, categoryId, sort, minPrice, maxPrice }) => ({
        url: `${END_POINTS.featuredProducts}?page=${page}&size=${
          size || 68
        }&categoryId=${categoryId}&sort=${sort}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
        method: "POST",
        body,
      }),
    }),
    getRecommendedProducts: builder.mutation<
      CommonResponseType & { data: FeaturedProductsType },
      {
        body: CommonBody;
        page: number;
        categoryId: string | undefined;
        sort: string;
        minPrice: number;
        maxPrice: number;
        size?: number;
      }
    >({
      query: ({ body, size, page, categoryId, sort, minPrice, maxPrice }) => ({
        url: `${END_POINTS.getProduct}?page=${page}&size=${
          size || 68
        }&categoryId=${categoryId}&sort=${sort}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
        method: "POST",
        body,
      }),
    }),
    getAllRecentProducts: builder.mutation<
      CommonResponseType & { data: RecentsProductsData },
      {
        body: CommonBody;
        page: number;
        categoryId: string | undefined;
        sort: string;
        minPrice: number;
        maxPrice: number;
        recomProduct?: boolean;
        size?: number;
      }
    >({
      query: ({
        recomProduct,
        body,
        page,
        categoryId,
        sort,
        minPrice,
        maxPrice,
        size,
      }) => ({
        url: `${
          END_POINTS.recentProducts
        }?page=${page}&size=${size}&categoryId=${categoryId}&sort=${sort}&minPrice=${minPrice}&maxPrice=${maxPrice}${
          recomProduct ? `&recomProduct=${recomProduct}` : ""
        }`,
        method: "POST",
        body,
      }),
    }),

    postSubscribeNewsLetter: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: `${END_POINTS.subscribeNewsletter}`,
        method: "POST",
        body,
      }),
    }),
    getSeller: builder.query<
      CommonResponseType & { data: any },
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `${END_POINTS.getSeller}?page=${page}&size=${size}`,
        method: "GET",
      }),
    }),
    getMostlySearched: builder.mutation<
      CommonResponseType & { data: any },
      {
        body: CommonBody;
        page: number;
        categoryId: string | undefined;
        sort: string;
        minPrice: number;
        maxPrice: number;
        size?: number;
      }
    >({
      query: ({ body, page, categoryId, sort, minPrice, maxPrice, size }) => ({
        url: `${END_POINTS.mostlySearched}?page=${page}&size=${size}&categoryId=${categoryId}&sort=${sort}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
        method: "POST",
      }),
    }),
    getMostlyViewed: builder.mutation<
      CommonResponseType & { data: any },
      {
        body: CommonBody;
        page: number;
        categoryId: string | undefined;
        sort: string;
        minPrice: number;
        maxPrice: number;
        size?: number;
      }
    >({
      query: ({ body, page, categoryId, sort, minPrice, maxPrice, size }) => ({
        url: `${END_POINTS.mostlyViewed}?page=${page}&size=${size}&categoryId=${categoryId}&sort=${sort}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
        method: "POST",
      }),
    }),
    getNewArrivals: builder.mutation<
      CommonResponseType & { data: any },
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `${END_POINTS.newArrival}`,
        method: "POST",
      }),
    }),
    getTopOffers: builder.mutation<
      CommonResponseType & { data: any },
      {
        body?: CommonBody;
        page: number;
        categoryId: string | undefined;
        sort: string;
        minPrice: number;
        maxPrice: number;
        size?: number;
      }
    >({
      query: ({ body, page, categoryId, sort, minPrice, maxPrice, size }) => ({
        url: `${END_POINTS.topDeals}?page=${page}&size=${size}&categoryId=${categoryId}&sort=${sort}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
        method: "POST",
      }),
    }),
    getHotDeals: builder.mutation<
      CommonResponseType & { data: any },
      {
        body?: CommonBody;
        page: number;
        categoryId: string | undefined;
        sort: string;
        minPrice: number;
        maxPrice: number;
        size?: number;
      }
    >({
      query: ({ body, page, categoryId, sort, minPrice, maxPrice, size }) => ({
        url: `${END_POINTS.hotDeals}?page=${page}&size=${size}&categoryId=${categoryId}&sort=${sort}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyGetDashboardQuery,
  useGetRecentProductsMutation,
  useGuestLoginMutation,
  useLazyGlobalSearchQuery,
  useLazyGetCategoriesQuery,
  usePostSaveSearchHistoryMutation,
  useLazyGetSearchHistoryQuery,
  usePostClearSearchMutation,
  useGetFeaturedProductsMutation,
  useGetRecommendedProductsMutation,
  useGetAllRecentProductsMutation,
  usePostSubscribeNewsLetterMutation,
  useLazyGetSellerQuery,
  useGetMostlySearchedMutation,
  useGetMostlyViewedMutation,
  useGetNewArrivalsMutation,
  useLazyDashboardBannerQuery,
  useLazyDashboardFeaturedProductsQuery,
  useLazyDashboardRecentProductsQuery,
  useLazyDashboardRecommendedProductsQuery,
  useGetTopOffersMutation,
  useGetHotDealsMutation,
} = authApi;
