import { Box, Modal } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import Login from "./Login";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import {
  ModalType,
  isAuthModalVisible,
  toggleAuthModal,
} from "../../reducers/generalSlice";
import ForgotPassword from "./ForgotPassword";
import Verification from "./Verification";
import ResetPassword from "./ResetPassword";
import SignUp from "./SignUp";
import ProfileSetup from "./ProfileSetup";
import ChangePassword from "./ChangePassword";

const Auth = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(ModalType);
  const style = generateResponsiveStyle();
  const AuthModal = useAppSelector(isAuthModalVisible);
  const [phone, setPhone] = useState(true);
  const [signUp, setSignUp] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");

  const closeModal = () => {
    dispatch(toggleAuthModal({ isAuthModalVisible: false, ModalType: "" }));
  };

  const handleModal = () => {
    switch (modal) {
      case "login":
        return (
          <Login
            closeModal={closeModal}
            setPhone={setPhone}
            setSignUp={setSignUp}
            setPhoneNumber={setPhoneNumber}
            setDialCode={setDialCode}
          />
        );
      case "forgot":
        return (
          <ForgotPassword
            closeModal={closeModal}
            setEmailValue={setEmailValue}
          />
        );
      case "verification":
        return (
          <Verification
            closeModal={closeModal}
            phone={phone}
            setPhone={setPhone}
            signUp={signUp}
            emailValue={emailValue}
            phoneNumber={phoneNumber}
            dialCode={dialCode}
          />
        );
      case "reset":
        return <ResetPassword closeModal={closeModal} setPhone={setPhone} />;
      case "signUp":
        return (
          <SignUp
            closeModal={closeModal}
            setPhone={setPhone}
            setEmailValue={setEmailValue}
            setPhoneNumber={setPhoneNumber}
            setDialCode={setDialCode}
            setSignUp={setSignUp}
          />
        );
      case "profileSetup":
        return (
          <ProfileSetup
            closeModal={closeModal}
            phone={phone}
            setPhone={setPhone}
            signUp={signUp}
            emailValue={emailValue}
            phoneNumber={phoneNumber}
            dialCode={dialCode}
          />
        );
      case "changePassword":
        return <ChangePassword closeModal={closeModal} setPhone={setPhone} />;
      default:
        return null;
    }
  };
  return (
    <Modal
      // BackdropClick={false}

      open={AuthModal}
      onClose={closeModal}
      hideBackdrop
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{handleModal()}</Box>
    </Modal>
  );
};

export default Auth;
