import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBixmvo8xlb23Fc7oO_UFHJNEciXEwYSVQ",
  authDomain: "krafo-dccbb.firebaseapp.com",
  projectId: "krafo-dccbb",
  storageBucket: "krafo-dccbb.appspot.com",
  messagingSenderId: "272647030954",
  appId: "1:272647030954:web:c2de569fbb49dee5ada8d3",
  measurementId: "G-LT82M4H53H",
};
const app = initializeApp(firebaseConfig);
export default app;
