export const en = {
  Globals: {
    report_ad: "Report this Ad",
    Login: "Login",
    sign_in: "Sign In",
    Continue: "Continue",
    update_detail: "Update Detail",
    sign_up: "Sign Up",
    email: "Email",
    phone_number: "Phone Number",
    next: "Next",
    full_name: "Full Name",
    address: "Address",
    submit: "Submit",
    clear: "Clear",
    no_product: "No Products Found",
    profile: "Profile",
    chat: "Chat",
    wishlist: "Wishlist",
    notifications: "Notifications",
    logout: "Logout",
    categories: "Categories",
    see_all: "See All",
    explore_more: "Explore More",
    recent_products: "Recently  Viewed",
    featured_products: "Featured Products",
    recommended_products: "Recommended Products",
    download_krafo: "Download The Krafo App",
    buy_sell:
      "Buy or Sell anything using the app on your mobile. Fashion, electronics, and more.",
    select: "Select",
    what_krafo: "What is Krafo ?",
    no_info: "Information not found",
    get_started: "Get Started",
    no_faq: "No Faq listing found",
    help: "How can we help",
    message: "Message",
    contact_now: "Contact Now",
    choose_cat: "Choose a Category",
    sub_cat: "Sub-Categories",
    clear_all: "Clear All",
    brand: "Brand",
    sort: "Sort By",
    price: "Price",
    apply: "Apply",
    apply_filter: "Apply Filter",
    new: "New",
    popular: "Popular",
    low_high: "Price Low to High",
    high_low: "Price High to Low",
    recent: "Recent",
    no_recent_notifications: "No Recent Notifications found",
    older_notifications: "Older Notifications",
    no_older_notifications: "  No Older Notifications found",
    clear_notifications: "Clear All Notifications",
    all: "All",
    yes: "Yes",
    no: "No",
    save: "Save",
    back: "Back",
    update: "Update",
    add: "Add",
    location: "Location",
    edit_detail: "Edit Detail",
    aed: "AED",
    for: "For",
    bill_total: "Bill Total",
    online_pay: "Online Payments",
    first_pay:
      "After your first payment, we will save your details for future use.",
    expiry_date: "Expiry Date",
    proceed: "Proceed",
    delete_card: "Are you sure to delete this card?",
    agree: "Agree, if you want to receive OTP via SMS/email",
    invalidImg: "Invalid file type. Please upload only images.",
    we_are: "We are",
    under_maintenance: "Under Maintenance ",
    apologies:
      "Apologies for the inconvenience, we're currently undergoing maintenance to enhance your experience. We'll be back online shortly!",
    refresh: "Refresh This Page",
    accepted: "Accepted",
    pending: "Pending",
    rejected: "Rejected",
    wholesale: "WHOLESALE",
    week: "week",
    weeks: "weeks",
    months: "months",
    year: "year",
    product_page: "Go to product's page",
    related_products: "Related Products",
    become_seller: "Become a Seller",
    business_profile: "Business Details",
    business_name: "Business Name",
    mobile_no: "Mobile Number",
    business_landline: "Business Landline",
    business_email: "Business Email",
    license_no: "License Number",
    license_no_optional: "License Number (Optional)",
    business_landline_optional: "Business Landline (Optional)",
    start_selling: "Start Selling",
    not_approved: "Your business details are not approved by admin yet",
    status: "Status",
    details_added:
      "Business details added successfully. Please wait for admin approval",
    new_arrivals: "New Arrivals",
    products: "Products",
    in_focus: "In Focus",
    mostly_search: "Mostly Search",
    mostly_view: "Mostly Viewed",
    explore_more_products: "Explore More Products",
    official_stores: "Official Stores",
    visit_store: "Visit the Store",
    user_details: "User Details",
    business_details: "Business Details",
    explore_products: "Explore Products",
    verification_porocess:
      "Verification process will take 24 hours. Once verified you will receive an email",
    agree_to: "Agree to",
    terms_conditions: "terms & conditions",
    accept_terms: "Please read and accept terms & conditions",
    register: "Register",
    details: "Details",
    //////
    blog: "Blog",
    seasonal_offers: "Seasonal Offers",
    top_offers: "Top Offers",
    hot_deals: "Hot Deals",
    seller_form: "Seller Form",
    weekly_deals: "Weekly Deals",
    top_manufacturers: "Top Manufacturers",
    minimum_quantity: "Minimum Quantity",
    askPrice: "Ask for price",
  },
  Auth: {
    Login: {
      enter_details: "Please enter your details",
      continue_with: "Continue With",
      or: "or",
      not_member: "Not a member?",
      remember: "Remember me",
      forgot_pas: "Forgot Password?",
      email_id: "Email Id",
      password: "Password",
    },
    Sign_up: {
      welcome: "Welcome User",
      confirm_password: "Confirm Password",
      sign_up_with: "Sign up With",
      already_member: "Already a member?",
    },
    forgot_password: {
      enter_email: "Enter your email here",
    },
    otp_verification: {
      verification: "Verification",
      email_code: "We have sent an OTP code to your email",
      phone_code: "We have just sent you a verification code via",
      resend: "Resend Code",
      code_expire: "The OTP code will expire in",
    },
    profile_setup: {
      setup: "Profile Setup",
    },
    reset_password: {
      reset: "Reset Password",
      new_password: "New Password",
    },
    change_password: {
      change: "Change Password",
      old_pass: "Old Password",
      new_pass: "New Password",
    },
  },
  header: {
    select_location: "Select Location",
    search: "Search by mobile phones, clothes & other",
    recently_searched: "Recently Searched",
    sell: "Sell",
    all_categories: " All Categories",
    sure_to_logout: "Are you sure you want to logout?",
    yes: "Yes",
    no: "No",
    home: "Home",
  },
  footer: {
    support: "Support",
    contact_us: "Contact Us",
    faq: "FAQ",
    about_us: "About Us",
    privacy: "Privacy Policy",
    follow_us: "Follow Us",
    subscribe: "Subscribe",
    enter_email: "Enter your email",
    krafo: "krafo",
    rights_reserved: "All Rights Reserved",
    text: "A platform for wholesale trade to showcase their products to customers worldwide.",
    terms_conditions: "Terms & Conditions",
    consumer_rights: "Consumer Rights",
    sell_with_us: "Sell With Us",
  },
  view_profile: {
    product_report: "Why you want to report this product?",
    share_profile: "Share Profile",
    report: "Report",
    total_products: "Total Products",
    total_followers: " Total Followers",
    total_following: "Total Following",
    follow: "Follow",
    unfollow: "Unfollow",
    my_products: "My Products",
    user_report: "Why you want to report this user?",
    your_reason: "Your reason",
  },
  profile: {
    my_booster: "My Booster",
    my_listing: "My Listing",
    manage_payments: "Manage Payments",
    manage_addresses: "Manage Addresses",
    delete_account: "Delete Account",
    sure_delete: "Are you sure you want to delete this account?",
    delete_product: "Are you sure you want to delete this product?",
    no_cards: "No Cards Found",
    new_card: "Add new card",
    pay_via: "Save and Pay via Cards.",
    enter_details: "Enter your card details.",
    holder_name: "Card holder name",
    card_number: "Card Number",
    cvv: "CVV",
    save_card: "Save this card for future payments?",
    add_address: "Add New Address",
    edit_address: "Edit address",
    house: "House / Flat / Block No.",
    landmark: "Landmark",
    save_As: "Save As",
    home: "Home",
    work: "Work",
    other: "Other",
    saved_address: "Saved Addresses",
    default: "Default",
    edit: "Edit",
    no_address: "No Address Found",
    delete_address: " Are you sure you want to delete this address?",
    no_followers: "No folowers found",
    no_following: "You are not following anyone yet",
    manage_profile: "Manage your profile details",
    invalid_email: "Invalid Email",
    verify: "Verify",
    invalid_number: "Invalid Number",
    plan_name: "Plan Name",
    plan_price: "Plan Price",
    plan_validity: "Plan Validity",
    expiry_date: "Expiry Date",
    description: "Description",
  },
  chat: {
    buying: "Buying",
    selling: "Selling",
    no_chats: "No Chats Found",
    go_ahead: "Go Ahead and start conversation",
    delete_chat: "Delete Chat",
    offer_price: "Offer Price",
    decline: "Decline",
    accept: "Accept",
    type_message: "Type a message",
  },
  product: {
    send: "Send",
    amount: "Amount",
    product_detail: "Product Detail",
    posted_on: "Posted on",
    share: "Share",
    member_since: "Member since",
    view_profile: "View Profile",
    more_details: "More Details",
    booster: "Booster",
    edit_detail: "Edit Detail",
    delete_product: "Delete Product",
    make_offer: "Make an offer",
    description: "Description",
  },
  add_product: {
    almost_there: "You’re almost there!",
    set_price:
      " Include as much details and pictures as possible, and set the right price!",
    add_pictue: "Add Picture",
    buy_booster: "Want to Buy Booster",
    select_sub: "Select Sub Category",
    select_brand: "Select Brand",
    product_name: "Product Name",
    price: "Price",
    location: "Location",
    descriprtion: "Description",
    publish_post: "Publish Post",
    update_post: "Update Post",
  },

  validations: {
    required_field: "This field is required",
    correct_phone: "Please enter correct phone number",
    email_required: "Email is required!",
    valid_email: "Enter a valid email address!",
    password_required: "Password is required!",
    password_validation:
      "Must contain 8 or more characters, one uppercase, one lowercase and one number.",
    password_match: "Passwords must match.",
    images_allowed: "Only images are allowed",
    min_three: "Minimum 3 characters are required",
    min_two: "Minimum 2 characters are required",
    min_seven: "Minimum 7 characters are required",
    max_fifteen: "Maximum 15 characters are allowed",
    max_forty: "Maximum 40 character are allowed",
    old_pass_required: "Old password is required.",
    new_pass_required: "New password is required.",
    new_pass_validation: "New password cannot be same as old password.",
    card_holder_name: "Card holder name must be at least 3 characters",
    phone_min: "Phone number must be at least 6 characters",
    phone_max: "Phone number must be utmost 16 characters",
    cardNo_val: "Card number must be at least 16 characters",
    cvv_val: "CVV must be atleast 3 characters",
  },
  toast_messages: {
    setup_complete: "Congratulations! Your profile setup is complete",
    password_updated: "Password is updated successfully",
    try_again: "Please try again",
    query_sent: "Query sent successfully",
    notifications_clear: "Notifications cleared successfully",
    report_sent: "Report Sent Successfully",
    account_deleted: "Account deleted successfully",
    address_added: "Address Added Successfully",
    address_updated: "Address Updated Successfully",
    select_default: "Please select default address",
    verify_phone: "Please verify phone number first",
    verify_email: "Please verify email first",
    profile_updated: "Profile updated successfully",
    chat_deleted: "Chat deleted successfully",
    enter_offer: "Please enter offer amount",
    image_required: "Images are required",
    product_added: "Product Added Successfully",
    product_updated: "Product Updated Successfully",
    product_deleted: "Product Deleted Successfully",
    agree: "Please agree to receive OTP to continue",
    location_updated: "Location updated successfully",
    max6: "Cannot upload more than 6 images",
  },
};
