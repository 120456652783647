import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import useAuth from "../../hooks/useAuth";
import { FollowList, FollowingList } from "../../types/General";
import {
  useLazyGetFollowingListQuery,
  usePostFollowUnfollowMutation,
} from "../../service/Product";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../helpers";
import { useLazyGetProfileQuery } from "../../service/Auth";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import useTranslation from "../../hooks/Translation";
import { useNavigate } from "react-router";

const TotalFollows = () => {
  const navigate = useNavigate();
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const dispatch = useAppDispatch();
  const user = useAuth();
  const [follows, setFollows] = useState<FollowingList[]>([]);
  const [getFollowers, { isLoading }] = useLazyGetFollowingListQuery();
  const [postFollow, GetFollowData] = usePostFollowUnfollowMutation();
  const [getProfile] = useLazyGetProfileQuery();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getFollowers({
        userId: user?._id,
        type: 2,
      }).unwrap();
      if (response?.statusCode === 200) {
        setFollows(response?.data?.following || []);
      } else {
        setFollows([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const handleUnfollowUser = async (id: string) => {
    try {
      const response = await postFollow({
        user_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        getUserDetails();
        fetchData();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="main">
      <Loader isLoad={isLoading} />
      <h2>{translation.view_profile.total_following}</h2>
      <ul className="followers_lst">
        {follows?.length ? (
          follows?.map((item) => {
            return (
              <li className="flw_lst">
                <div
                  className="lst_lft follow_click"
                  onClick={() =>
                    navigate(`/view-profile/${item?.following?._id}`)
                  }
                >
                  <figure
                    className="delete_dv"
                    style={{
                      marginRight: language === "ar" ? 0 : "16px",
                      marginLeft: language === "ar" ? "16px" : "0px",
                    }}
                  >
                    <LazyLoadImage
                      alt="user_placeholder"
                      src={
                        item?.following?.image ||
                        "/static/images/user_placeholder.png"
                      }
                      effect="blur"
                      height={"100%"}
                      width={"100%"}

                      // scrollPosition={scrollPosition}
                    />
                  </figure>
                  <h4>{item?.following?.fullName || ""}</h4>
                </div>
                <div className="lst_ryt">
                  <Button
                    value="Unfollow"
                    onClick={() => handleUnfollowUser(item?.following?._id)}
                  />
                </div>
              </li>
            );
          })
        ) : (
          <div className="no_followers_found">
            <p>{translation.profile.no_following}</p>
          </div>
        )}
      </ul>
    </div>
  );
};

export default TotalFollows;
