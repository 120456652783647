import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ProductBannerData } from "../../types/General";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Skeleton } from "@mui/material";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
};

const WeeklyDealBanner = ({ loading, bannerData }: any) => {
  return (
    <section className="container home_banner_sc">
      {!loading ? (
        <Slider {...settings}>
          {bannerData?.map((item: any) => {
            return item?.link?.length ? (
              <a
                key={item?._id}
                href={
                  item?.link?.startsWith("http")
                    ? item?.link
                    : `https://${item?.link}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="bnr">
                  <LazyLoadImage
                    className="bnr_img_style"
                    src={item?.image}
                    alt="banner"
                    effect="blur"
                    // height={"240px"}
                    width={"100%"}
                  />
                </div>
              </a>
            ) : (
              <div className="bnr">
                <LazyLoadImage
                  className="bnr_img_style"
                  src={item?.image}
                  alt="banner"
                  effect="blur"
                  // height={"240px"}
                  width={"100%"}
                />
              </div>
            );
          })}
        </Slider>
      ) : (
        <Skeleton variant="rounded" width={"100%"} height={240} />
      )}
    </section>
  );
};

export default WeeklyDealBanner;
