import { END_POINTS } from "../helpers";
import {
  CommonBody,
  DashboardData,
  ProductsByCategory,
  RecommendedProductsData,
  Subcategories,
  globalSearch,
} from "../types/General";
import { Category } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type CategoryByIdData = {
  count: number;
  maxPrice: number;
  pages: number;
  products: ProductsByCategory[];
};
type SubcategoriesData = {
  count: number;
  pages: number;
  subCategory: Subcategories[];
};
type CategoriesData = {
  categoryObjects: Category[];
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductsByCategoryId: builder.query<
      CommonResponseType & { data: CategoryByIdData },
      {
        categoryId: string;
        page: number;
        minPrice: number;
        maxPrice: number;
        sort: string;
        brand: string[];
        subCategoryId: string;
      }
    >({
      query: ({
        categoryId,
        page,
        minPrice,
        maxPrice,
        sort,
        brand,
        subCategoryId,
      }) => ({
        url: `${
          END_POINTS.getProductsByCategoryId
        }/${categoryId}?page=${page}&minPrice=${minPrice}&maxPrice=${maxPrice}&sort=${sort}&brand=${brand}&size=${68}&subCategoryId=${subCategoryId}`,
        method: "GET",
      }),
    }),
    getSubCategories: builder.query<
      CommonResponseType & { data: SubcategoriesData },
      { categoryId: string; page: number; limit: number }
    >({
      query: ({ categoryId, page, limit }) => ({
        url: `${END_POINTS.subCategory}/${categoryId}?page=${page}&size=${limit}`,
        method: "GET",
      }),
    }),
    getFilteredCategories: builder.query<
      CommonResponseType & { data: CategoriesData },
      { type: number }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.getMyProductFilter}?type=${type}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetProductsByCategoryIdQuery,
  useLazyGetSubCategoriesQuery,
  useLazyGetFilteredCategoriesQuery,
} = categoryApi;
