import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormControl, Input } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, InputField } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import { UploadMedia } from "../../utils/uploadMedia";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import {
  useJustSendOtpMutation,
  useJustVerifyOtpMutation,
  useLazyGetProfileQuery,
  usePostSetProfileMutation,
} from "../../service/Auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { setCredentials } from "../../reducers/authSlice";
import { isString } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";
import OTPInput from "react-otp-input";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type props = {
  closeModal: () => void;
  phone: boolean;
  setPhone: Dispatch<SetStateAction<boolean>>;
  signUp: boolean;
  emailValue?: string;
  phoneNumber?: string;
  dialCode?: string;
};

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
  paddingBottom: 20,
};

const ProfileSetup = ({ closeModal, setPhone, phone, signUp }: props) => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const user = useAuth();
  const dispatch = useAppDispatch();

  const [phoneCode, setPhoneCode] = useState(user?.countryCode || "+971");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [emailVerified, setEmailVerified] = useState(
    user?.isEmailVerify || false
  );

  const [phoneVerified, setPhoneVerified] = useState(
    user?.isPhoneVerify || false
  );

  const [newEmail, setNewEmail] = useState(user?.email || "");
  const [newPhone, setNewPhone] = useState(user?.phone || "");
  const [newCountryCode, setNewCountryCode] = useState(user?.countryCode || "");
  const [isPhone, setIsPhone] = useState(false);

  const [image, setImage] = useState("");
  const [postSetProfile] = usePostSetProfileMutation();

  const [verificationPopup, setVerificationPopup] = useState(false);

  const [sendOtp, GetSendOtpData] = useJustSendOtpMutation();
  const [verifyOtp] = useJustVerifyOtpMutation();
  const [getProfile] = useLazyGetProfileQuery();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // for sending otp
  const sendVerificationCode = async (val: number) => {
    let data = {
      ...(val === 1
        ? {
            key: formik.values.phone,
            countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          }
        : {
            key: formik.values.email,
          }),
    };

    if (
      formik.values?.phone?.length > 6 ||
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        formik.values.email
      )
    ) {
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await sendOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");
          setCountDown(59);
          setVerificationPopup(true);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const handleResendOtp = async () => {
    let data = {
      ...(isPhone
        ? {
            phone: formik.values.phone,
            countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          }
        : {
            key: formik.values.email,
          }),
      resend: true,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await sendOtp(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast(response?.message || "");
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  //for otp verification
  const handleVerifyOtp = async () => {
    if (otp?.length === 4) {
      setError(false);

      const data = {
        ...(!user?.isEmailVerify
          ? { key: formik.values.email, code: otp }
          : {
              key: formik.values.phone,
              countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
              code: otp,
            }),
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await verifyOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (!user?.isEmailVerify) {
            setEmailVerified(true);
            setNewEmail(formik.values.email);
            setOtp("");
          } else {
            setPhoneVerified(true);
            setNewPhone(formik.values.phone);
            setNewCountryCode(phoneCode);
            setOtp("");
          }
          setVerificationPopup(false);
          successToast(response?.message || "");
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleImageUpload = async (file: any) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
        } else {
          errorToast(translation.validations.images_allowed);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      setImage("");
      errorToast(translation.validations.images_allowed);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: user?.fullName || "",
      email: user?.email || "",
      phone: user?.phone || "",
      address: user?.address || "",
      lat: "",
      long: "",
      state: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translation.validations.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.validations.valid_email
        ),
      fullName: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.min_three)
        .max(40, translation.validations.max_forty),

      phone: Yup.string()
        .required(translation.validations.required_field)
        .min(8, translation.validations.correct_phone)
        .max(14)
        .label(translation.Globals.phone_number),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let data = {
        fullName: formik.values.fullName,

        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        image: image,
        isPhoneVerify: true,
        isEmailVerify: true,
        isProfileComplete: true,
        ...(phone && signUp
          ? {
              sendPassword: true,
            }
          : {}),
      };

      if (!phoneVerified && !user?.isPhoneVerify) {
        errorToast(translation.toast_messages.verify_phone);
      } else if (!emailVerified && !user?.isEmailVerify) {
        errorToast(translation.toast_messages.verify_email);
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await postSetProfile(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            dispatch(
              toggleAuthModal({ isAuthModalVisible: false, ModalType: "" })
            );
            const token = getFromStorage(STORAGE_KEYS.token);

            successToast(translation.toast_messages.setup_complete);
            dispatch(
              setCredentials({
                user: response?.data || null,
                token: JSON.parse(`${token}`),
              })
            );
            getUserDetails();
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    setImage(user?.image || "");
  }, []);

  useEffect(() => {
    if (verificationPopup) {
      if (countDown > 0) {
        setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      } else {
        setCountDown(0);
      }
    }
  }, [countDown, verificationPopup]);

  return (
    <div>
      <Loader isLoad={GetSendOtpData?.isLoading} />
      {verificationPopup ? (
        <div className="AuthWrap">
          <div className="cross">
            <CloseIcon
              onClick={() => {
                setPhone(true);
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: false,
                    ModalType: "",
                  })
                );
              }}
            />
          </div>
          <h2>{translation.Auth.otp_verification.verification}</h2>

          {!user?.isPhoneVerify ? (
            <p>
              {translation.Auth.otp_verification.phone_code}{" "}
              <span>
                {phoneCode && formik.values.phone
                  ? (phoneCode.includes("+") ? "" : "+") +
                    phoneCode +
                    formik.values.phone
                  : ""}
              </span>
            </p>
          ) : (
            <p>
              {translation.Auth.otp_verification.email_code}{" "}
              <span>{formik.values.email || ""}</span>
            </p>
          )}

          <FormControl className="opt_fields" sx={{ width: "100%" }}>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={otpStyle}
              inputType="tel"
              shouldAutoFocus
            />
            <br />
            {error && otp.length !== 4 ? (
              <h6 className="err_msg" style={{ textAlign: "center" }}>
                {translation.validations.required_field}
              </h6>
            ) : (
              ""
            )}
          </FormControl>

          <Button
            value={translation.Globals.next || ""}
            onClick={handleVerifyOtp}
          />

          {countDown === 0 ? (
            <div className="resend">
              <h4 onClick={handleResendOtp}>
                {translation.Auth.otp_verification.resend}
              </h4>
            </div>
          ) : (
            <div className="continue" style={{ marginBottom: "10px" }}>
              <h5>
                {" "}
                {translation.Auth.otp_verification.code_expire} 00 :{" "}
                {countDown < 10 ? `0${countDown}` : countDown}
              </h5>
            </div>
          )}
        </div>
      ) : (
        <div className="AuthWrap">
          <div className="cross ">
            <CloseIcon
              onClick={() =>
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: false,
                    ModalType: "",
                  })
                )
              }
            />
          </div>
          <h2>{translation.Auth.profile_setup.setup}</h2>
          <div className="image">
            <label htmlFor="icon-button-file">
              <Input
                sx={{ display: "none" }}
                id="icon-button-file"
                type="file"
                inputProps={{
                  accept: "image/png,image/jpeg/ image/jpg",
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.files && event.target.files[0]) {
                    setImage(URL.createObjectURL(event.target.files[0]));
                    handleImageUpload(event.target.files[0]);
                  }
                }}
              />

              <figure>
                {!image ? (
                  <LazyLoadImage
                    alt="user"
                    src={"/static/images/empty_user.png" || ""}
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                  />
                ) : (
                  <LazyLoadImage
                    alt="user"
                    src={image}
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                  />
                )}
              </figure>
              <AddIcon />
            </label>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <InputField
              placeholder={translation.Globals.full_name}
              name="fullName"
              id="fullName"
              value={formik.values.fullName}
              onChange={(val) => {
                if (
                  val.target.value === " " ||
                  val.target.value.includes(".")
                ) {
                } else if (isString(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              inputProps={{ maxLength: 40 }}
              onBlur={formik.handleBlur}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
            <div className="vrfctn_dv2">
              <InputField
                placeholder={translation.Auth.Login.email_id}
                disabled={user?.isEmailVerify}
                name="email"
                inputProps={{ maxLength: 80 }}
                id="profile_email"
                value={formik.values.email}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.email && formik.errors.email}
              />
              {!emailVerified || newEmail !== formik.values.email ? (
                <div>
                  {user && (
                    <div>
                      <button
                        style={{
                          right: language === "ar" ? "unset" : "15px",
                          left: language === "ar" ? "15px" : "unset",
                        }}
                        className={
                          !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                            formik.values.email
                          )
                            ? "vrfctn_btn2"
                            : "vrfctn_btn"
                        }
                        type="button"
                        onClick={() => {
                          // setVerificationPopup(true);
                          setIsPhone(false);
                          sendVerificationCode(0);
                        }}
                      >
                        {formik.values?.email?.length === 0
                          ? ""
                          : !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                              formik.values.email
                            )
                          ? translation.profile.invalid_email
                          : translation.profile.verify}
                      </button>
                    </div>
                  )}
                </div>
              ) : user?.isEmailVerify || emailVerified ? (
                <div>
                  <figure
                    className="verified"
                    style={{
                      right: language === "ar" ? "unset" : "15px",
                      left: language === "ar" ? "15px" : "unset",
                    }}
                  >
                    <LazyLoadImage
                      alt="phonetick"
                      src={"/static/images/phonetick.png"}
                      effect="blur"
                      height={"100%"}
                      width={"100%"}
                    />
                  </figure>
                </div>
              ) : null}
            </div>
            <div className="form_control">
              <div className="vrfctn_dv">
                <PhoneInput
                  country={"in"}
                  disabled={user?.isPhoneVerify}
                  value={phoneCode + formik.values.phone}
                  onChange={(phone: any, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  dropdownStyle={{
                    bottom: " 40px",
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="0 (000) 000-000"
                  enableSearch={true}
                  inputStyle={{ width: "100%", backgroundColor: "#EBEBEB" }}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <h6 className="err_msg">
                    {formik.touched.phone && formik.errors.phone}
                  </h6>
                ) : (
                  ""
                )}{" "}
                {!phoneVerified ||
                newPhone !== formik.values.phone ||
                newCountryCode !== phoneCode ? (
                  <div>
                    {user && (
                      <div>
                        <button
                          style={{
                            right: language === "ar" ? "unset" : "15px",
                            left: language === "ar" ? "15px" : "unset",
                          }}
                          className={
                            formik.values?.phone?.length === 0
                              ? "vrfctn_btn2"
                              : formik.values?.phone?.length < 6
                              ? "vrfctn_btn2"
                              : "vrfctn_btn"
                          }
                          type="button"
                          onClick={() => {
                            setIsPhone(true);
                            sendVerificationCode(1);
                            // setVerificationPopup(true);
                          }}
                        >
                          {formik.values?.phone?.length === 0
                            ? ""
                            : formik.values?.phone?.length < 6
                            ? translation.profile.invalid_number
                            : translation.profile.verify}
                        </button>
                      </div>
                    )}
                  </div>
                ) : user?.isPhoneVerify || phoneVerified ? (
                  <div>
                    <figure
                      className="verified"
                      style={{
                        right: language === "ar" ? "unset" : "15px",
                        left: language === "ar" ? "15px" : "unset",
                      }}
                    >
                      <LazyLoadImage
                        alt="phonetick"
                        src={"/static/images/phonetick.png"}
                        effect="blur"
                        height={"100%"}
                        width={"100%"}
                      />
                    </figure>
                  </div>
                ) : null}
              </div>
              <div style={{ marginBottom: 10 }} />
            </div>

            <Button value={translation.Globals.submit} />
          </form>
        </div>
      )}

      <div style={{ marginBottom: 20 }} />
    </div>
  );
};

export default ProfileSetup;
