import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Layout from "../../layout";
import {
  Button,
  Pagination,
  ProductCard,
  ProductCardSkeleton,
} from "../../components";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";
import {
  Category,
  CommonBody,
  RecentProduct,
  RecommendedProductsData,
  Subcategories,
} from "../../types/General";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";
import {
  useGetAllRecentProductsMutation,
  useGetFeaturedProductsMutation,
  useGetHotDealsMutation,
  useGetMostlySearchedMutation,
  useGetMostlyViewedMutation,
  useGetRecommendedProductsMutation,
  useGetTopOffersMutation,
} from "../../service/Home";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Slider,
} from "@mui/material";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLazyGetSubCategoriesQuery } from "../../service/category";
import useTranslation from "../../hooks/Translation";
import useCategory from "../../hooks/useCategory";

function valuetext(value: number) {
  return `${value}°C`;
}

const SeeAll = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const cat = searchParams.get("cat");
  const sort = searchParams.get("sort");
  const minPriceValue = Number(searchParams.get("minPrice"));
  let maxPriceValue = Number(searchParams.get("maxPrice"));
  const pageValue = Number(searchParams.get("page"));
  const subCategoryIds = searchParams.get("subCat");
  const subCat = subCategoryIds?.length ? subCategoryIds?.split(",") : [];

  const categories = useCategory();
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const name = useParams();

  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getFeaturedProducts] = useGetFeaturedProductsMutation();
  const [getRecommendedProducts] = useGetRecommendedProductsMutation();
  const [getRecentProducts] = useGetAllRecentProductsMutation();
  const [getMostSearched] = useGetMostlySearchedMutation();
  const [getMostViewed] = useGetMostlyViewedMutation();
  const [getTopOffers] = useGetTopOffersMutation();
  const [getHotDeals] = useGetHotDealsMutation();

  const [subCategory] = useLazyGetSubCategoriesQuery();

  const [subCategories, setSubCategories] = useState<Subcategories[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const [selectedValue, setSelectedValue] = useState<any>("");
  const [selectedSubCategories, setSelectedSubCategories] = useState<any>([]);

  const [favItems, setFavItems] = useState<any[]>([]);
  const [maxPrice, setMaxPrice] = useState<number>(1000000000);
  const [value, setValue] = useState<number[]>([0, maxPrice]);
  const [newSliderValue, setNewSliderValue] = useState<number[]>([]);

  const [recentProducts, setRecentProducts] = useState<
    RecommendedProductsData[]
  >([]);

  let totalPages = Math.ceil(totalCount / 68);

  const handleApis = (stateData: any) => {
    if (name?.name === "featured") {
      getAllFeaturedProducts(stateData);
    } else if (name?.name === "recommended") {
      // getAllRecentProducts(stateData, "recomProduct");
      getAllRecommendedProducts(stateData);
    } else if (name?.name === "recent") {
      getAllRecentProducts(stateData, "");
    } else if (name.name === "arrival" || name.name === "explore") {
      getAllRecommendedProducts(stateData);
    } else if (name.name === "search") {
      getMostSearchedProducts(stateData);
    } else if (name.name === "view") {
      getMostViewedProducts(stateData);
    } else if (name.name === "top-offers") {
      fetchTopOffers(stateData);
    } else if (name.name === "hot-deals") {
      fetchHotDeals(stateData);
    }
  };

  const onPageChange = (newPage: number) => {
    let topElement = document.getElementById("top_corner");

    setTimeout(() => {
      topElement?.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);

    setPage(newPage);
    let stateData = {
      cat: selectedCategoryId,
      sort: selectedValue,
      minPriceValue: value[0],
      maxPriceValue: value[1],
      pageValue: newPage,
      subCat: selectedSubCategories,
    };
    handleApis(stateData);
    navigate(
      `/all-products/${name.name}?cat=${selectedCategoryId}&subCat=${
        selectedSubCategories?.length ? selectedSubCategories.join(",") : ""
      }&sort=${selectedValue}&minPrice=${value[0]}&maxPrice=${
        value[1]
      }&page=${newPage}`,
      { replace: true }
    );
  };

  const handleClearFilters = () => {
    setSelectedValue("");
    setSelectedCategory(null);
    setSelectedCategoryId("");
    setSelectedSubCategories([]);
    setValue([0, maxPrice || 10000000000]);
    setNewSliderValue([]);

    let stateData = {
      cat: "",
      sort: name.name === "recommended" ? "alpha" : "",
      minPriceValue: 0,
      maxPriceValue: 10000000000,
      pageValue: 1,
      subCat: [],
    };
    handleApis(stateData);
    if (name.name === "recommended") {
      navigate(`/all-products/${"recommended"}?sort=${"alpha"}`);
    } else {
      navigate(`/all-products/${name.name}`);
    }
  };

  const handleSubCategoryChange = (subCategory: string) => {
    if (selectedSubCategories.includes(subCategory)) {
      setSelectedSubCategories((prevSelected: any[]) =>
        prevSelected.filter((item) => item !== item)
      );
    } else {
      setSelectedSubCategories((prevSelected: any) => [
        ...prevSelected,
        subCategory,
      ]);
    }
  };

  const sortingOptiopns = [
    { value: "new", label: translation.Globals.new },
    { value: "popular", label: translation.Globals.popular },
    { value: "lowToHigh", label: translation.Globals.low_high },
    { value: "highToLow", label: translation.Globals.high_low },
  ];
  const handleSortingOptions = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const getSubCategory = async (id: string) => {
    try {
      const response = await subCategory({
        categoryId: id,
        page: 1,
        limit: 50,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubCategories(response?.data?.subCategory || []);
      } else {
        setSubCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAllFeaturedProducts = async (stateData?: any) => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      subCategoryId: stateData?.subCat || [],
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };

    setLoading(true);
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getFeaturedProducts({
        body: encryptedBody,
        page: stateData?.pageValue ? stateData?.pageValue : 1,
        categoryId: stateData?.cat ? stateData?.cat : "",
        sort: stateData?.sort ? stateData?.sort : "",
        minPrice: stateData?.minPriceValue ? stateData?.minPriceValue : 0,
        size: 68,
        maxPrice: stateData?.maxPriceValue
          ? stateData?.maxPriceValue
          : 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setRecentProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);
        setNewSliderValue([0, response?.data?.maxPrice || 10000]);
        setLoading(false);
      } else {
        setLoading(false);
        setRecentProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const fetchTopOffers = async (stateData: any) => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      subCategoryId: stateData?.subCat || [],
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };
    setLoading(true);
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getTopOffers({
        body: encryptedBody,
        page: stateData?.pageValue ? stateData?.pageValue : 1,
        categoryId: stateData?.cat ? stateData?.cat : "",
        sort: stateData?.sort ? stateData?.sort : "",
        minPrice: stateData?.minPriceValue ? stateData?.minPriceValue : 0,
        size: 68,
        maxPrice: stateData?.maxPriceValue
          ? stateData?.maxPriceValue
          : 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setRecentProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);
        setNewSliderValue([0, response?.data?.maxPrice || 10000]);
      } else {
        setLoading(false);
        setRecentProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };
  const fetchHotDeals = async (stateData: any) => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      subCategoryId: stateData?.subCat || [],
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };
    setLoading(true);
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getHotDeals({
        body: encryptedBody,
        page: stateData?.pageValue ? stateData?.pageValue : 1,
        categoryId: stateData?.cat ? stateData?.cat : "",
        sort: stateData?.sort ? stateData?.sort : "",
        minPrice: stateData?.minPriceValue ? stateData?.minPriceValue : 0,
        size: 68,
        maxPrice: stateData?.maxPriceValue
          ? stateData?.maxPriceValue
          : 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setRecentProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);
        setNewSliderValue([0, response?.data?.maxPrice || 10000]);
      } else {
        setLoading(false);
        setRecentProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const getAllRecommendedProducts = async (stateData?: any) => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);

    const data = {
      subCategoryId: stateData?.subCat || [],
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };

    setLoading(true);

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getRecommendedProducts({
        body: encryptedBody,
        page: stateData?.pageValue ? stateData?.pageValue : 1,
        categoryId: stateData?.cat ? stateData?.cat : "",
        sort: stateData?.sort ? stateData?.sort : "",
        minPrice: stateData?.minPriceValue ? stateData?.minPriceValue : 0,
        size: 68,
        maxPrice: stateData?.maxPriceValue
          ? stateData?.maxPriceValue
          : 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setRecentProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);

        setNewSliderValue([0, response?.data?.maxPrice || 10000]);
        setLoading(false);
      } else {
        setLoading(false);

        setRecentProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      setLoading(false);

      errorToast(error?.data?.message || "");
    }
  };

  const getAllRecentProducts = async (stateData: any, type?: string) => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      subCategoryId: stateData?.subCat || [],
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };

    setLoading(true);

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getRecentProducts({
        body: encryptedBody,
        page: stateData?.pageValue ? stateData?.pageValue : 1,
        categoryId: stateData?.cat ? stateData?.cat : "",
        sort: stateData?.sort ? stateData?.sort : "",
        minPrice: stateData?.minPriceValue ? stateData?.minPriceValue : 0,
        size: 68,
        maxPrice: stateData?.maxPriceValue
          ? stateData?.maxPriceValue
          : 10000000000,
        recomProduct: type ? true : false,
      }).unwrap();
      if (response?.statusCode === 200) {
        setRecentProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);

        setNewSliderValue([0, response?.data?.maxPrice || 10000]);
        setLoading(false);
      } else {
        setLoading(false);

        setRecentProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const getMostSearchedProducts = async (stateData?: any) => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);

    const data = {
      subCategoryId: stateData?.subCat || [],
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };

    setLoading(true);

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getMostSearched({
        body: encryptedBody,
        page: stateData?.pageValue ? stateData?.pageValue : 1,
        size: 68,
        categoryId: stateData?.cat ? stateData?.cat : "",
        sort: stateData?.sort ? stateData?.sort : "",
        minPrice: stateData?.minPriceValue ? stateData?.minPriceValue : 0,
        maxPrice: stateData?.maxPriceValue
          ? stateData?.maxPriceValue
          : 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setRecentProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);

        setNewSliderValue([0, response?.data?.maxPrice || 10000]);
        setLoading(false);
      } else {
        setLoading(false);

        setRecentProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      setLoading(false);

      errorToast(error?.data?.message || "");
    }
  };
  const getMostViewedProducts = async (stateData: any) => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);

    const data = {
      subCategoryId: stateData?.subCat || [],
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };

    setLoading(true);

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getMostViewed({
        body: encryptedBody,
        page: stateData?.pageValue ? stateData?.pageValue : 1,
        size: 68,
        categoryId: stateData?.cat ? stateData?.cat : "",
        sort: stateData?.sort ? stateData?.sort : "",
        minPrice: stateData?.minPriceValue ? stateData?.minPriceValue : 0,
        maxPrice: stateData?.maxPriceValue
          ? stateData?.maxPriceValue
          : 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setRecentProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);
        setNewSliderValue([0, response?.data?.maxPrice || 10000]);
        setLoading(false);
      } else {
        setLoading(false);

        setRecentProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      setLoading(false);

      errorToast(error?.data?.message || "");
    }
  };

  const handleFilterClick = () => {
    onPageChange(page);

    setShowFilters(false);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  useEffect(() => {
    if (token || tempToken) {
      if (cat) {
        getSubCategory(cat);
      }
      let stateData = {
        cat,
        sort,
        minPriceValue,
        maxPriceValue,
        pageValue,
        subCat,
      };
      handleApis(stateData);
    }
  }, [tempToken]);

  useEffect(() => {
    if (pageValue || sort || cat || minPriceValue || maxPriceValue) {
      setPage(pageValue);
      setSelectedValue(sort ? sort : "");
      setSelectedCategoryId(cat ? cat : "");
      setValue([minPriceValue, maxPriceValue]);
      setSelectedSubCategories(subCat);
    }
  }, []);

  const handleCategoryClick = (item: Category) => {
    setPage(1);
    setSelectedCategory(item);
    setSelectedCategoryId(item?._id);
    setSelectedSubCategories([]);
    getSubCategory(item?._id);
  };

  const checkFavProperties = () => {
    // if (name?.name === "featured") {
    //   const fav_data = featuredProducts
    //     .filter((item: any) => item?.isFav)
    //     .map((item: any) => item);
    //   setFavItems(fav_data);
    // } else {
    const fav_data = recentProducts
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
    // }
    // else {
    //   const fav_data = recentProducts
    //     .filter((item: any) => item?.isFav)
    //     .map((item: any) => item);
    //   setFavItems(fav_data);
    // }
  };

  useEffect(() => {
    checkFavProperties();
  }, [recentProducts]);

  return (
    <Layout>
      <div id="top_corner" />
      <div className="wrapCls">
        <div className="container">
          <div className="filters">
            <div className="filters_dv">
              <h2>
                {name?.name === "featured"
                  ? translation.Globals.featured_products
                  : name?.name === "recommended"
                  ? translation.Globals.recommended_products
                  : name?.name === "arrival"
                  ? translation.Globals.new_arrivals
                  : name.name === "explore"
                  ? translation.Globals.explore_products
                  : name?.name === "recent"
                  ? translation.Globals.recent_products
                  : name?.name === "search"
                  ? translation.Globals.mostly_search
                  : name?.name === "view"
                  ? translation.Globals.mostly_view
                  : name?.name === "top-offers"
                  ? translation?.Globals?.top_offers
                  : name.name === "hot-deals"
                  ? translation?.Globals?.hot_deals
                  : translation.Globals.recent_products}
              </h2>
              <figure>
                <LazyLoadImage
                  src="/static/images/filter_icn.svg"
                  onClick={() => setShowFilters(!showFilters)}
                  alt="filter_icn"
                  effect="blur"
                  width={"100%"}
                  height={"100%"}
                />
              </figure>
            </div>
            <div className="sub_catMain">
              <div className={showFilters ? "sc_lft mob_dsply" : "sc_lft "}>
                <div className="inner">
                  <div className="sub">
                    <div className="filtr_top_hdng">
                      <h4>{translation.Globals.categories}</h4>
                      {selectedCategory ||
                      selectedSubCategories?.length ||
                      selectedValue ? (
                        <p onClick={() => handleClearFilters()}>
                          {translation.Globals.clear_all}
                        </p>
                      ) : null}
                    </div>

                    <ul>
                      {loading ? (
                        <li>
                          {Array.from({ length: 4 }, (_, index) => (
                            <Skeleton
                              key={index}
                              variant="text"
                              animation="wave"
                              style={{
                                fontSize: "1rem",
                                width: "100px",
                                paddingBottom: "4px",
                              }}
                            />
                          ))}
                        </li>
                      ) : categories?.length ? (
                        categories?.map((item) => {
                          return (
                            <li
                              key={item?._id}
                              onClick={() => handleCategoryClick(item)}
                              className={
                                selectedCategoryId === item?._id
                                  ? "actv_cat"
                                  : ""
                              }
                            >
                              {language === "ar"
                                ? item?.name_ar || ""
                                : item?.name || ""}
                            </li>
                          );
                        })
                      ) : null}
                    </ul>
                  </div>
                  {(selectedCategory &&
                    selectedCategory?.subCateoryName?.length) ||
                  cat ? (
                    <div className="sub">
                      <h3>{translation.Globals.sub_cat}</h3>
                      <ul>
                        {subCategories?.length
                          ? subCategories?.map((subCategory) => (
                              <li key={subCategory?._id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedSubCategories?.length &&
                                        selectedSubCategories.includes(
                                          subCategory?._id
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        handleSubCategoryChange(
                                          subCategory?._id
                                        )
                                      }
                                    />
                                  }
                                  name={
                                    language === "ar"
                                      ? subCategory?.name_ar
                                      : subCategory?.name
                                  }
                                  label={
                                    language === "ar"
                                      ? subCategory?.name_ar || ""
                                      : subCategory?.name || ""
                                  }
                                />
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  ) : null}
                  <div className="sub">
                    <h3>{translation.Globals.sort}</h3>
                    <ul className="sort_options">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedValue}
                        onChange={handleSortingOptions}
                      >
                        {sortingOptiopns?.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </ul>
                  </div>

                  <div className="sub">
                    <h3>{translation.Globals.price}</h3>
                    <ul style={{ paddingTop: 0 }}>
                      <li>
                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                          max={maxPrice}
                        />
                        <div
                          style={{
                            marginTop: 10,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{ color: "#0D0D0D", fontSize: "14px " }}>
                            {translation.Globals.aed}{" "}
                            {value[0]?.toLocaleString("en-IN")}
                          </span>
                          <span style={{ color: "#0D0D0D", fontSize: "14px " }}>
                            {translation.Globals.aed}{" "}
                            {value[1]?.toLocaleString("en-IN")}
                          </span>
                        </div>

                        <div>
                          <Button
                            value={translation.Globals.apply_filter}
                            onClick={handleFilterClick}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={showFilters ? "sc_Ryt hide_prdcts" : "sc_Ryt"}>
                <div className="prodcts_sc">
                  <div className="crds_sec">
                    {loading ? (
                      Array.from({ length: 9 }, (_, index) => (
                        <ProductCardSkeleton key={index} />
                      ))
                    ) : recentProducts?.length ? (
                      recentProducts?.map((item) => (
                        <ProductCard
                          wishlist={item?.isFav}
                          key={item?._id}
                          product_id={item?._id}
                          booster_applied={item?.boosterApplied}
                          product_image={item?.images[0]}
                          product_feature={
                            language === "ar"
                              ? item?.category?.name_ar
                              : item?.category?.name
                          }
                          product_name={item?.name}
                          product_price={item?.price}
                          location={item?.address}
                          setFavItems={setFavItems}
                          favItems={favItems}
                          item={item}
                          lang={item?.lang}
                          routePath={`/product-detail/${
                            item?._id
                          }?cat_id=${""}&name_en=${encodeURIComponent(
                            name?.name || ""
                          )}`}
                        />
                      ))
                    ) : (
                      <div className="noData top_mt">
                        <figure>
                          <LazyLoadImage
                            src="/static/images/empty.png"
                            alt="empty"
                            effect="blur"
                            width={"100%"}
                            height={"100%"}
                          />
                        </figure>
                        <h3>{translation.Globals.no_product}</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={showFilters ? " hide_prdcts" : ""}>
              {recentProducts?.length ? (
                <Pagination
                  module={recentProducts}
                  page={page || 1}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SeeAll;
